import { render, staticRenderFns } from "./Klarna.vue?vue&type=template&id=13ce7dc7&scoped=true&"
import script from "./Klarna.vue?vue&type=script&lang=js&"
export * from "./Klarna.vue?vue&type=script&lang=js&"
import style1 from "./Klarna.vue?vue&type=style&index=1&id=13ce7dc7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ce7dc7",
  null
  
)

export default component.exports