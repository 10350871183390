export default class Contactos {
	constructor(deps){
		this.deps = deps
		this.axios = this.deps.axios
		this.api = this.deps.api
	}

	async getAreas(){		
		return await this.api.post('contactos/getAreas', {})
	}

	async set(params){
		return await this.api.post('contactos/set', params)
	}
}