// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg width=%2712%27 height=%2724%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M6 1.984.973 8 0 7.161 6 0l6 7.161-.973.839zm0 20.032L11.027 16l.973.839L6 24l-6-7.161L.973 16z%27 fill-rule=%27evenodd%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg width=%2712%27 height=%2724%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M6 1.984.973 8 0 7.161 6 0l6 7.161-.973.839zm0 20.032L11.027 16l.973.839L6 24l-6-7.161L.973 16z%27 fill=%27%230432ff%27 fill-rule=%27evenodd%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".calculadora-aplazame .aplazame-widget-instalments{min-width:300px;padding-left:16px;padding-right:32px;color:#000;border-radius:8px;border:1px solid #ddd;white-space:nowrap;margin-bottom:12px;background-color:#fff;font-size:15px;font-weight:400;height:45px;line-height:45px;text-align:center;vertical-align:middle;z-index:1;position:relative}.calculadora-aplazame .aplazame-widget-instalments select{font-size:13px;-webkit-appearance:none;-moz-appearance:none;appearance:none;position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer}.calculadora-aplazame .aplazame-widget-from{text-transform:capitalize;display:inline-block}.calculadora-aplazame .aplazame-widget-amount{font-weight:500}.calculadora-aplazame .aplazame-widget-per-month{vertical-align:baseline}.calculadora-aplazame .aplazame-widget-instalments-wrapper{font-weight:400}.calculadora-aplazame .aplazame-widget-instalments:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")!important;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-position:calc(100% - 14px) 50%;background-size:9px;content:\"\";display:block;position:absolute;top:0;left:0;right:0;bottom:0;z-index:-1;border-radius:2px;transform:scaleX(1);transition:transform .2s ease;backface-visibility:hidden;-webkit-font-smoothing:subpixel-antialiased;transition:all .2s,transform .2s}.calculadora-aplazame em,.calculadora-aplazame sub{font-style:normal}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
