export default class Iberent {
	constructor(deps){
		this.deps = deps
		this.axios = this.deps.axios
		this.api = this.deps.api
	}

	async simular(params){
		return await this.api.post('iberent/simular', params)
	}
}