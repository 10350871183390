///CCD: Campaña Captación Datos
export default class CCD {
	constructor(id, deps){
		this.deps = deps
		this.axios = this.deps.axios
		this.api = this.deps.api
		this.id = id
	}

	async getData(){
		return await this.api.post('ccds/get', {id: this.id})
	}

	async newLead(data, codidioma){
		let params = {
			idccd: this.id,
			datos: data,
			codidioma: codidioma
		}
		
		return await this.api.post('ccds/newLead', params)
	}
}