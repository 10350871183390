<template>
	<div>
		<table role="table" class="order-history">
			<caption class="visually-hidden">Historial de reservas</caption>
			<thead role="rowgroup">
				<tr role="row">
					<th scope="col" role="columnheader">Reserva</th>
					<th scope="col" role="columnheader">Fecha</th>
					<th scope="col" role="columnheader">Productos</th>
					<th scope="col" role="columnheader">Estado</th>
				</tr>
			</thead>
			<tbody role="rowgroup">
				<tr role="row" v-for="reserva in aux.reservas" :key="reserva.id">
					<td role="cell" headers="ColumnOrder" data-label="Reserva">
						<a href="#" style="pointer-events:none">
							{{reserva.numreserva}}
						</a>
					</td>
					<td headers="RowOrder ColumnDate" role="cell" data-label="Fecha">
						{{formatFecha(reserva.dtreserva)}}
					</td>
					<td headers="RowOrder" role="cell" data-label="Productos">
						<!--div v-for="producto in reserva.productos" :key="producto.idproducto">
							{{producto.unidades}} x {{producto.title}}
						</div-->
						<div class="reserva-producto" v-for="(producto, idx) in reserva.lineas_estado" :key="idx">
							<div>
								{{producto.unidades}} x {{producto.principal ? producto.principal.title : ''}}
							</div>							
							<div v-if="producto.principal && producto.principal.applecare_product && reserva.codestado == 'PDT_PAGO' && reserva.codunidad_reserva=='WWW'">
								{{producto.unidades}} x {{producto.principal.applecare_product.title}}								

								<div :id="'apple-care-add-' + producto.principal.idproducto + '-' + reserva.codigo" class="pdp-care-btn btn product-form__submit" @click="chgAppleCare(producto.principal.applecare_product)">{{producto.principal.applecare_product.badded ? 'Agregado' : 'Agregar'}}</div>
							</div>
							<div class="reserva-producto-alternativas" v-if="producto.alternativas.length > 0">
								<div v-for="alternativa in producto.alternativas" :key="alternativa.id">
									{{alternativa.title}}
								</div>
							</div>								
						</div>
					</td>
					<td headers="RowOrder" role="cell" data-label="Estado">
						<div>
							<div>{{reserva.estado}}</div>
							<div v-if="esPagable(reserva) && reserva.dtlimite_pago" style="font-size: 0.8em; margin-top: 0.8em; color: #888;">
								Disponible hasta:<br>{{formatFechaHora(reserva.dtlimite_pago)}}
							</div>
							<div style="font-size: 0.8em; margin-top: 0.8em; color: #888;" v-if="reserva.codestado == 'PDT_PAGO' && reserva.codunidad_reserva!='WWW' && reserva.area">Pago y gestión de reserva: INTECAT | {{reserva.area}}</div>
							<div style="margin-top:20px;padding-bottom:20px" v-if="esActiva(reserva)">
								<div><a href="#" @click="pagar(reserva.codigo, reserva.lineas_estado)" v-if="reserva.codestado == 'PDT_PAGO' && reserva.codunidad_reserva=='WWW'" :disabled="!esPagable(reserva)">Completar el pago</a></div>
								<div><a href="#" @click="cancelar(reserva.codigo)">Cancelar reserva</a></div>
							</div>
						</div>
					</td>					
				</tr>
			</tbody>
		</table>

		<modal ref="cancelModal" title="Cancelar reserva">
			<div>
				¿Seguro que quieres cancelar esta reserva?
			</div>
			<template slot="footer">
				<el-button type="default" style="margin-right:10px" @click="abortCancel">No, quiero mantenerla</el-button>
				<el-button type="danger" @click="confirmCancel">Sí, quiero cancelarla</el-button>
			</template>
		</modal>
	</div>
</template>

<script>
import i18n from '@/mixins/i18n'
import Modal from '@/components/Modal'

import {format as datefns_format, parseISO as datefns_parseISO, isAfter as datefns_isAfter} from 'date-fns'
import datefns_localeES from 'date-fns/locale/es'

export default {
	props: ['url-api'],
	mixins: [i18n],
	components: {Modal},
	data(){
		//this.loadi18nMessages('component', 'reserva')
		this.$api.init(this.urlApi)
		
		return {
			data: {
				nombre: null,
				apellidos: null,
				telefono: null,
				email: null,
				email2: null,
				unidades: 1,
				bentrega_total: 1,
				bnewsletter: false
			},
			aux: {
				reservas: []
			},
			control: {
				benviando: false,
				current_step: "summary",
				bedit_datos_personales: false,
				codreserva: null
			},
			dict: {
				es: {

				},
				ca: {

				}
			}
		}
	},
	mounted(){
		this.getReservas()
	},
	methods: {
		chgAppleCare(applecare_product){
			applecare_product.badded = applecare_product.badded ? 0 : 1
		},
		getImageSrc(img, px){
			let path = img.split(".")

			let ext = path.pop()
			let url = path.join(".")

			return url + "_x" + px + "." + ext
		},
		esPagable(reserva){
			return reserva.codestado == 'PDT_PAGO' && datefns_isAfter(new Date(), datefns_parseISO(reserva.dtinicio_pago))
		},
		esActiva(reserva){
			return ['PDT_MATERIAL','PDT_PAGO','NO_CONTESTA','CONFIRMADO_TIENDA'].includes(reserva.codestado)
		},
		formatFecha(dt){
			return datefns_format(datefns_parseISO(dt), "d 'de' MMMM 'de' yyyy", {locale: datefns_localeES})
		},
		formatFechaHora(dt){
			return datefns_format(datefns_parseISO(dt), "d 'de' MMMM 'de' yyyy 'a las' HH:mm", {locale: datefns_localeES})
		},
		async getReservas(){
//let result = await this.api.post('cliente/nuevaReserva', params)
			//let {data} = await axios.post("https://des-store.intecat.com/shopify/reservas/getReservas", {}, { withCredentials: true })
			this.aux.reservas = await this.$api.reservas.listAll()
		},
		/*async validatePagar(codigo){
			let reserva = this.aux.reservas.find(r => r.codigo == codigo)
			if(reserva){
				reserva.lineas_estado.forEach(linea => {
					if(linea.principal.applecare_product){

					}
				})
			}
		},*/
		async pagar(codigo, lineas){
			let loading

			try{
				loading = this.$loading()

				// Miramos si se ha escogido algún producto adicional (Applecare o garantía)
				let productos_agregados = {}
				lineas.forEach(linea => {
					let applecare_product  = linea.principal.applecare_product
					if(applecare_product && applecare_product.badded){
						productos_agregados["p-"+linea.principal.idproducto] = applecare_product
					}
				})

				let data = await this.$api.reservas.pagar(codigo, productos_agregados)
				//let {data} = await axios.post("https://des-store.intecat.com/shopify/reservas/pagar", {codigo: codigo}, { withCredentials: true })
//console.log(data)
				if (data.checkout_url) location.href = data.checkout_url
			}catch(e){
				this.$alert("Se ha producido un error inesperado al redirigir a la pantalla de pago.", "Atención")
				console.log(e)
			}finally{
				loading.close()
			}			
		},
		async cancelar(codigo){
			this.$refs.cancelModal.open()
			this.control.codreserva = codigo
			/*let loading
			this.$confirm("Seguro que quieres cancelar esta reserva?", "Cancelar reserva", {cancelButtonText: "No, quiero mantenerla", confirmButtonText: "Sí, quiero cancelarla", confirmButtonClass: 'el-button--danger'})
				.then(async () => {
					loading = this.$loading()
					try{
						//let {data} = await axios.post("https://des-store.intecat.com/shopify/reservas/cancelar", {codigo: codigo}, { withCredentials: true })
						//console.log(data)
						await this.$api.reservas.cancelar(codigo)
						await this.getReservas()
					}catch(e){
						this.$alert("Se ha producido un error inesperado cancelando la reserva.", "Atención")
						console.log(e)
					}finally{
						loading.close()
					}
				})
				.catch(_ => {
					//loading.close()
				})*/
		},
		abortCancel(){
			this.$refs.cancelModal.close()
		},
		async confirmCancel(){
			this.$refs.cancelModal.close()
			
			let loading = this.$loading()
			try{
				await this.$api.reservas.cancelar(this.control.codreserva)
				await this.getReservas()
			}catch(e){
				this.$alert("Se ha producido un error inesperado cancelando la reserva.", "Atención")
				console.log(e)
			}finally{
				loading.close()
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	$color-foreground: 18 18 18;
	
	table{
		table-layout: auto;
		border-collapse: collapse;
		border-bottom: 0.01rem solid rgb($color-foreground);
		box-shadow: none;
		width: 100%;
		font-size: 1.6rem;
		position: relative;
		font-family: SF Pro Text, Arial, sans-serif;
	}

	th, td {
		font-weight: 400;
		line-height: 1;
		border: none;
		padding: 0;
		
	}

	thead th {
		font-size: 12px;
		letter-spacing: .07rem;
		text-transform: uppercase;
	}

	thead th, tbody td {
		padding-top: 2.4rem;
		padding-bottom: 2.4rem;
	}

	tbody {
		color: rgb($color-foreground);
	}

	tbody td {
		vertical-align: top;
	}

	@media screen and (min-width: 750px){
		table {
			border: none;
			box-shadow: 0 0 0 0.1rem rgb($color-foreground);
		}

		th, td:first-of-type {
			text-align: left;
			padding-left: 0;
			padding-right: 2.2rem;
		}

		th:first-of-type, td:first-of-type {
			padding-left: 2.2rem;
		}

		table td:first-of-type {
			padding-top: 1.2rem;
			padding-bottom: 1.2rem;
		}
	}

	.reserva-producto{
		white-space:nowrap;

		.reserva-producto-alternativas{
			color:#aaa;
			margin: 0px 20px;
			/*margin-left:20px;
			margin-top:5px;*/
		}
	}
</style>

<style lang="scss">
body{
	font-size:12px;
}
</style>