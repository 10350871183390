<template>
	<div class="calculadora-klarna">
		<div class="msg-importe" v-if="min && importe < min">El pago a plazos con Klarna no está disponible para importes inferiores a {{formatCurrency(min)}}.</div>
		<div class="msg-importe" v-if="max && importe > max">El pago a plazos con Klarna no está disponible para importes inferiores a {{formatCurrency(max)}}.</div>
		<klarna-placement data-key="credit-promotion-auto-size" data-locale="es-ES" :data-purchase-amount="amount" v-if="initialized"></klarna-placement>
	</div>
</template>

<script>
import utils from '@/mixins/utils'

export default {
	props: ['importe', 'min', 'max', 'client-id'],
	mixins: [utils],
	data() {
		return {
			initialized: false
		}
	},
	computed: {
		amount(){
			return parseInt(this.importe*100)
		}		
	},
	mounted(){
		setTimeout(() => {
			this.loadScript()
		}, 1)
	},
	methods: {
		loadScript(){
			let script = document.createElement('script')
			script.type = 'text/javascript'
			script.async = true
			script.defer = true //quiza quitar
			script.setAttribute("data-client-id", this.clientId)
			script.setAttribute("data-environment", 'production')
			script.src = 'https://js.klarna.com/web-sdk/v1/klarna.js'
			document.head.appendChild(script)
			this.$nextTick(() => {
				this.initialized = true
			})
		}
	}
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.calculadora-klarna{
	.msg-importe{
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
		font-size:13px;
		line-height:20px;
		font-weight:500;
		color: #17120f;
	}
	
	klarna-placement{
		&::part(osm-container){
			padding:0;
			text-align:left;
			background:transparent;
			border:none;
		}

		&::part(osm-message), &::part(osm-cta){
			font-size:13px;
			line-height:20px;
			font-weight:500;
		}
	}
}
</style>