import { render, staticRenderFns } from "./Formulario.vue?vue&type=template&id=1562c4f9&scoped=true&"
import script from "./Formulario.vue?vue&type=script&lang=js&"
export * from "./Formulario.vue?vue&type=script&lang=js&"
import style1 from "./Formulario.vue?vue&type=style&index=1&id=1562c4f9&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1562c4f9",
  null
  
)

export default component.exports