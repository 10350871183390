<template>
	<el-button v-bind="$attrs" @click="onClick" :disabled="isDisabled">
		<slot/>
	</el-button>
</template>

<script>
export default {
	props: ['disabled'],
	data(){
		return {
			control: {
				executing: false
			}
		} 
	},
	computed: {
		isDisabled(){
			let propDisabled = this.disabled || false
			return this.control.executing ? true : propDisabled
		}
	},
	methods: {
		async onClick(){
			try{
				this.control.executing = true
				if(this.$listeners.click) await this.$listeners.click()
			}catch(e){
			}finally{
				this.control.executing = false	
			}
		}
	}
}
</script>

<style lang="scss">

</style>