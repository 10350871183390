<template>
	<div ref="modal" class="i-modal-wrapper" :class="{'visible': visible}">
		<div ref="modal-content" class="i-modal-content" :style="{width: this.size}">
			<div class="i-modal-header">
				<div class="i-modal-title">{{title}}</div>
				<button class="i-modal-close" type="button" aria-label="Cerrar" @click="close">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" style="width:16px" fill="none" viewBox="0 0 18 17">
						<path d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z" fill="currentColor"></path>
					</svg>
				</button>
			</div>
			<div class="i-modal-body" :key="times_visible">
				<slot/>
			</div>
			<div class="i-modal-footer">
				<slot name="footer"/>
			</div>
		</div>
		<div class="i-modal-overlay" @click="close"/>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: ""
		},
		size: {
			type: String,
			default: "500px"
		}		
	},
	data(){
		return {
			visible: false,
			times_visible: 0
		}
	},
	mounted(){
		this.init()
	},
	methods: {
		init(){
			let modal = this.$refs.modal
			let parent = modal.parentNode

			parent.removeChild(modal)
			document.body.appendChild(modal)
		},
		open(){
			this.$refs["modal-content"].scrollTop = 0
			this.visible = true
			this.times_visible++
			document.addEventListener('keydown', this.handleEscape.bind(this))
			document.documentElement.classList.add("non-scrollable")
		},
		close(){
			this.visible = false
			document.removeEventListener('keydown', this.handleEscape.bind(this))
			document.documentElement.classList.remove("non-scrollable")
		},
		handleEscape(e){
			if (e.key === 'Escape') this.close()
		}
	}
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.i-modal-wrapper{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	pointer-events:none;
	opacity:0;
	transition:0.3s all ease;
	z-index:1000;
	color:#121212;

	.i-modal-content{
		position:absolute;
		top: 60px;
		left:50%;
		background: #fff;
		border-radius: 8px;
		width: 500px;
		max-height: 500px;
		max-width:calc(100% - 40px);
		z-index:1001;

		//border-left:1px solid rgba(18,18,18,0.1);
		transition:0.3s all ease;
		transform: translate3d(-50%,-60px,0);
		opacity:0;
		overflow:auto;
	}

	&.visible{
		opacity:1;
		pointer-events:initial;

		.i-modal-content{
			transition:0.3s all ease;
			transform: translate3d(-50%,0,0);
			opacity:1;
		}
	}

	.i-modal-overlay{
		background:rgba(0,0,0,0.2);
		z-index:1000;
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:block;
	}

	.i-modal-header{
		padding: 24px;
		
		display:flex;
		gap:20px;

		.i-modal-title{
			font-family: SF Pro Text, Helvetica, Arial, sans-serif;
			font-size:16px;
			flex:1;
		}
	}

	.i-modal-close{
		/*position:absolute;
		top:24px;
		right:24px;*/
		outline:none;
		background:none;
		border:none;

		&:hover{
			opacity:0.8;
			cursor:pointer;
		}
	}
	
	.i-modal-body{
		padding: 24px;
	}

	.i-modal-header+.i-modal-body{
		padding-top:0;
	}

	.i-modal-footer{
		text-align:right;
		padding:24px;
		padding-top:0;			
	}
}

@media screen and (min-width: 750px){
	.i-modal .i-modal-content{
		//transform: translate(100%);
		width: 375px;
	}
}

html.non-scrollable{
	overflow:hidden !important;
}
</style>