<template>
	<drawer ref="drawer" :title="$msg('title')" width="400px" :paddingInBody="false">
		<div style="width:100%;height:100%;box-sizing:border-box;padding:0px;overflow:hidden">
			<iframe style="width:100%;height:100%;border:none" :src="url"/>
		</div>
	</drawer>
</template>

<script>
import Drawer from '@/components/Drawer'
import i18n from '@/mixins/i18n'

export default {
	components: {Drawer},
	props: ['url'],
	mixins: [i18n],
	data(){
		return {
			control: {
				drawer_visible: false
			},
			dict: {
				es: {
					"title": "Plan Renove",
				},
				ca: {
					"title": "Pla Renove"
				}
			}
		}
	},
	methods: {
		open(){
			this.$refs.drawer.open()
			//this.control.drawer_visible = true
		}
	}
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
</style>