<template>
	<div class="steps" :style="style">
		<transition-group :name="'slide-fade-' + direction" tag="div" class="full">
			<div v-for="s in $slots.default.filter(slot => slot.tag)" :key="s.data.attrs.code" v-show="s.data.attrs.code == current_step_code" class="full">
				<vnode-renderer :vnode="s" @next="next" @prev="prev"/>
			</div>
		</transition-group>
	</div>
</template>

<script>
export default {
	props: ['page', 'first-step-code', 'width', 'height'],
	components: {
		VnodeRenderer: {
			functional: true,
			render: (h, ctx) => ctx.props.vnode
		}
	},
	data() {
		return {
			current_step: 0,
			current_step_code: this.firstStepCode,
			direction: null
		}
	},
	computed: {
		style(){
			let style = {}

			if (this.width) style['width'] = this.width
			if (this.height) style['height'] = this.height
			
			return style
		}
	},	
	methods: {
		next(step_code){
			this.direction = "right"
			this._set(step_code)			
		},
		prev(step_code){
			this.direction = "left"
			this._set(step_code)
		},
		_set(step_code){
			this.$nextTick(() => this.current_step_code = step_code)
			//if (this.$ga) this.$ga.page("/" + this.$store.state.locale + "/" + this.page + "/" + step_code)
		},
		set(step_code){
			this.direction = null
			this._set(step_code)
		}
	}
}
</script>

<style lang="scss" scoped>
	.steps{
		position:relative;
		/*overflow-x:hidden;*/
		/*overflow:hidden;*/
		/*overflow-x:hidden;*/
	}
	
	.slide-fade-left-enter-active,
	.slide-fade-right-enter-active,
	.slide-fade-left-leave-active,
	.slide-fade-right-leave-active {
		transition: all 0.8s ease;
	}
	
	.slide-fade-left-enter,
	.slide-fade-left-leave-to,
	.slide-fade-right-enter,
	.slide-fade-right-leave-to{
		opacity: 0;
	}

	.slide-fade-left-enter{	transform: translateX(-100%); }
	.slide-fade-left-leave-to{ transform: translateX(100%); }
	.slide-fade-right-enter{ transform: translateX(100%); }
	.slide-fade-right-leave-to{	transform: translateX(-100%); }

	.full{
		position:absolute;
		width:100%;
		height:100%;
		left:0;
		top:0;
	}
</style>
