<template>
	<div class="step-wrapper">
		<div class="step">
			<slot/>
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
	.step-wrapper{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		overflow-x:hidden;
		overflow-y:auto;

		.step{
			position:absolute;
			top:0;
			left:0;
			/*width:calc(100% + 50px);*/
			width:100%;
			height:100%;
			overflow-y: scroll;
			padding-right: 50px; /* Increase/decrease this value for cross-browser compatibility */
			box-sizing: content-box;
		}
	}
</style>
