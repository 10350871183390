export default{	
	props: {
		codidioma: {
			type: String,
			default: 'es'
		}
	},
	data(){
		return {
			dict: {},
			fallback_locale: 'es'
		}
	},
	methods: {
		loadi18nMessages(){
			//this._dict = require('./locales/' + this.codidioma + ".json")
			
			//this.$i18n.mergeLocaleMessage(locale, messages)
			//this._i18n_cfg = {type: type, name: name, key: key}
		},
		$msg(path, opts = {}){
			let dict = this.dict[this.codidioma] || this.dict[this.fallback_locale] || {}

			let str = path.split('.').reduce((o, k) => {
				return o && o[k]
			}, dict)

			return str.replace(/{\w+}/g, (placeholder) => {
				return opts[placeholder.substring(1, placeholder.length - 1)] || placeholder
			})
		}
	}
}