export default class Formularios {
	constructor(deps){
		this.deps = deps
		this.axios = this.deps.axios
		this.api = this.deps.api
	}

	async getDefinicion(id){
		return await this.api.post('formularios/get', {id})
	}
}