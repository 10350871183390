<template>
	<div>
		<template v-if="data.productos.length > 0">
			<div class="i-simulator-wrapper">
				<div class="i-simulator-title">Opciones de financiación</div>
				<div class="i-simulator-box">
					<div class="i-simulator-menu">
						<div class="i-simulator-menu-option" :class="{'active': isActive(s.cod)}" v-for="s in simuladores" :key="s.cod" @click="control.active_simulator = s.cod">
							<img class="i-simulator-logo" :src="s.logo"/>
						</div>
					</div>
					<div class="i-simulator-content">						
						<template v-for="s in simuladores">
							<div v-show="isActive(s.cod)" :key="s.cod">
								<div v-if="s.label">{{s.label}}</div>
								<component :is="s.componentName" :modo="modo" :url-api="urlApi" v-bind="s.getProps(context)" :style="s.contentStyle"/>
							</div>
						</template>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import Icon from '@/components/Icon'
import Cetelem from '@/components/Simuladores/Cetelem.vue'
import Aplazame from '@/components/Simuladores/Aplazame.vue'
import Iberent from '@/components/Simuladores/Iberent.vue'

export default {
	props: ['modo', 'tipos', 'url-api', 'cetelem-min', 'aplazame-public-key'],
	components: {Icon, Aplazame, Cetelem, Iberent},
	data(){
		this.$api.init(this.urlApi)

		return {
			data: {
				productos: []
			},
			aux: {
			},
			control: {
				active: [],
				active_simulator: null
			}
		}
	},
	computed: {
		simuladores(){
			let disponibles = {
				'CETELEM': {
					label: '',
					componentName: 'Cetelem',
					contentStyle: '',
					logo: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJcAAAAqCAMAAACJFTngAAAAilBMVEUAAAA3lzg4mDk2mDk3mDk3mDk3mTg3mDk3nDdClUI3mDk3mDk3mDg3mDk3mDk3mDk3mDk3mDk2lzg3mDk3mDk3mTk2mDk3mDk3mDk3mDk2mDk3mTk2mTg3mDk3lzk4lzg3lzk4mDo2mDk2mDg3mDk2mDk1mDk5lzk3mjc3mDk3mDg2mTg3mDk3mDkV0hA7AAAALXRSTlMAjHz3sO5K+wsH0souKN/Dn5Yd4oLWzqrnajvaX71jYFIjpJput0IYRIhbEnQ8FRnEAAAC40lEQVRYw+2Y65KiMBCFo5H7XQO4OLpeZ2cc+/1fb6ehSchAJX92tpgqv1/m9BFOdUiIsm8hyXiWsPlRAUDF5gcg7L8Sh46YY64QAJoZ5sI7/nrmeuYifnoutwgdvs1EMhQD9/KpprfrH+2WafRJfTKbwHARumPF00ys28FaZCmvCpfpHEvouSVK3fUiX/ikgaSymCYvIon3pHpFwILCo9E+HrYlhwH8hdTzUN3FX3M5FtP0RYiGKzU7ZWrAGxXrBjqHViWz51CM+EsuYTEZLsISHE/jyPnKu6A38VhUgGykykUcsNNhg5+3H/0tC/eT2GICQ53t27D1UkTQE4ll7bVTSbHcVj6vu/6G2O5ejTqR+QWOLvp6tJjAUG/aSW0bc+eA8Hvbxh2o1xwmgSUj/Bwcl9RUraAaTSctl8UEhnqOSWi+roBcaX4xZc6QV5RrpvgIerVRor+l8DKXzQSGeoQTRGLg4EMV0OiMDWZIgTO9ZgqplkPhHReOymU3gaHuUIfks7bvB9duoVP2lR6K1Gw54ELrgXLZTWCp/2bEahjgpfsmZRejXFuYgKtcdhMY6/ZcfPTCI3VMpXLZTWCo/9t+8TeVy24CQ92Sy/B8hbguFhrLhKlcdhOY6vZc9vU4nmBhMwFiqttzjfcvP+h36oaNSXu3yQSIsW7PxcrJ/X4nt2qdM7Y3xk8GEyDmuj2XC0hN78eS3o93FMOkT3vdrzrDEfUUm2AwAWKu23Oxd0C82+JRhOo8Ubdi/rZma/fdUd8NO7N4NZgAsdTtudhl6vzlVzSSR0k6aHIaP6ZNKpehbstF6OfVO7V9BZr8SuYjBSunTSqXuf4ymeuu5ZKHcLQkUj1spOrlJyk3YSvdpk36/znTdbxAzAgx3Nhj+qb+e2iTLeOhGBzz0vGc8nz40H+m1CmPkrFp/P/XdD2ONg+1L9Xb2pejx7aK2ZMn385bCjobl80BGUsFY3NgrrnmOo+z5C9L+Rs+OzyD5gAAAABJRU5ErkJggg==',
					getProps: (ctx) => {
						return {
							importe: ctx.total_cimp,
							min: parseFloat(this.cetelemMin)
						}
					}
				},
				'IBERENT': {
					label: 'Renting para autónomos y empresas',
					componentName: 'Iberent',
					contentStyle: 'margin:-4px;margin-top:0;',
					logo: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIUAAAAqCAMAAACqTmg1AAAAjVBMVEUAAAAdcbgdcbgdcLkdcbgdcbgccbgccbgecbkccLoZc7cdcroccrgZcLYccbgdcbkdcLgdcbgdcLgdcbgdcbgdcbgdcLgccbgdcbgdcbgccbgdcbgccbgdcbgccbgccbgdcbgccbgccLkbcbYdcbgdcbgecbsdcbgdcrkccLkdcLgec7kdcrgfcrodcbhmhSNWAAAALnRSTlMAwtSF+fSP4l8eCgRlFOi2L3hJsNruqVmsm3/LiMe9oJNqOg2mbxDQQyRTGE4o9hXDUAAAAy1JREFUWMPtl9mWojAQQEsWoyK7DMqiILjb9f+fNybRLDRqe+bBMzPeF5tYVN0kFWjhRoxohfBu8MIAXuQfs/ARMX67BVL+b4vJyLfDd1s4BBHnt75o32SxRWYheKPFr3dbuPcs1kU8s2xvUK1BxwnSMBwFoDCN4/YycPSJlZTm+BqXtqVnG3E6UQJ9f+UCBMPcs8hyswVGFGa06C4MI9YXvrSw8YZXNCA4Ly0+OstcMUiH4q2PHKugdTcEr9ihowaGk4XIPAKKLGV3z8gAJeQInNEMJV6qLp+n3FBCE6NCflACV0uU8NMp6VpsUGXIDvUKdSqRXGfQGPoAOclAneixxWmw2qT1MLaRsQBwd8hJDMNChqklt9rMpBO1Dy1f3zasK+5jTDSLZOl7Vz9HXfZBx0LQ1IRP2+HBZEOX19mXfCpK8h1ry6/Wmo6QEo/5LibSV22UiHsc9TOiWyhsl4ikAd5Tmei0IetRmdwew42ZdvDWNv12KwKtCBhjm7XJDy3AmV+MI6SkIInFYiBPJ8oyP6HLW2wqAiu4krG4n1rAZAEOm18GCgHrF3HrFG4s9EtobK1GpNkmzy0kNdtpaEJTQjc877EYsDkaEptei8Cx9tzG5xb6S886wBE7eD0WBHtIvltMdIt516LsXYoKIMUOVo+FhX28ZkFE60sajxY8wfe1SHosPOyBvGTBd/UEGuatEbfmUKOIeixyerkZaaTj1ywK+lmAyp6t/dhZQD/SQj5JaujwzELvzoCVDEASeFysRvOZhTyBO+ePLPiWGAe4kSZsYOJePiuZ283cOxaQa08xx2RfvbYjECDFnvKC0Y5fBrBipycCTkSwdO5YrNnAnJcLSsTzAwvxl6FZQIYM79dw4Sconv1DZMyqME0z5rbQLSQFv2me1WbJ3zGPLYCwdGfVgs5axz6Lk6JC7lnAHHX2jyxk6rVqASFqGF9iF/ThuxZQaJNI1cBTj4Wz47KaBQRLpVgtWrIppIdtTtSfuF4AGvtcRMYHNTAH7YXTAuOQcwta1wdBkA2I5RlLs5u98o3EIv7GhSes6X/aXs4TPGcUz7wxfPjw4cNfxW+gZQpey2Z0sgAAAABJRU5ErkJggg==',
					getProps: (ctx) => {
						return {
							productos: ctx.productos
						}
					}
				},
				'APLAZAME': {
					label: '',
					componentName: 'Aplazame',
					contentStyle: '',
					logo: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMkAAAAqCAMAAAAuyI8bAAAAk1BMVEUAAAAAU/8AU/8AU/8AU/8AU/8AU/8AUv8AU/8AU/8AUv8AUv8AU/8AU/8AUf8AV/8AU/8AVP8AU/8AU/8AU/8AU/8AU/8AUv8AU/8AU/8AU/8AU/8AV/8AU/8AU/8AUv8AU/8AU/8AU/8AU/8AU/8AUv8AU/8AU/8AU/8AU/8AU/8AU/8AU/8AUv8AUv8AU/8AU/+YRIN6AAAAMHRSTlMA3vjxfm/7FtntOCjJLBwNzxHpt4JpraZ5dFwyCCPUwrKNhlNMReW9kT+flFaaZGA1Bv+gAAAEA0lEQVRo3u2Y2ZaiMBCGC1CQRRRtcN/3bTrv/3QjlUAlhE43Z250Tv8XkpMUSX1ZKoXwq9fWhOU64a/bg+/FUPB6GqJjaR8f+zcmmdvoWLjBx+2NSS7o1xH2+Oy8MckW/bpCz8VC/L4kEfr1WRbeliQRS1EszuhtSe7olvUsHbA0eFuSB7r1oCA2K1pauboAu1HHsa1hmnxBkqRDy3Y6ox1AF9/JKzusIstgDB+5AvAmq6Pt97chxtRpPu5xrW73uD20nt6cxguNxMJx7nnxhMWJ4vC0GzEhP60l+eMXBlE4xaeBRDemHtPDQLS5Y4C9VVh2Qpq0jc0Km6ungix4Pda2sHhSSFoDRrrVkGwYadAykBiMgffuUON4wkj+onBW6fU4U0hSrFxiOcCyPZdJXCarpZGMlXbXRKIbE4lZKwHiq9VOVybJsO4MKD4pByKpyp1VSLpEijKS6MZEYtYFD05fOGFZoqNOkSWS83s5A9tUSJat/W575OVhhWQodsp5et9YsnOLbqGID78zGEMx0j726Hycg174wX2+0eZ32vMnVMqXZ0sgAR8mkbPivkpyxd3mrRgqJBI6ZREmBr01OSdERyM1GvNiH0eK7eKNXNvSpQQn3Q4ANbMRK6HbZJXrCqJZHH+ZJBJtvQE/imUDoftFf0udpM2rtmZjJm/rTE4AY/7Ss/RJq0AH9A98IZ9HXpnkosaGtUKSqXF7r5HcxboajGmouZwIPoQZzr37LIywOugJzcwZSSbGRVWufK8MQdVz7dGpq5BcbDpdBmMaipZxrNx3tIYksXInu04uD9QSyaoSHByFxBdVhSKVpMudjXrwjXE5SUTSrpL4dSR+nsIbFFL3GRZpTl2FxK180mQKSXzk88aXQTduRlLrsYubtF7UCx14acf6P1+TZMk3Dd7Q/74mA1YjB85GkjV1TyPHfIJ/fE7mK94BXqYGYxOJfk4OgaJZ3qtBjhRQ7iD0UQYLIlkrg8KhaKHN4/LQWmvcjGRU/4+Jh+J9nzzSTIReIrHmANKF1TbdJ33JuRuTI3qdcUOSTxEGhXbiS4CLLrtKkv9BJOJOnvEGNzbc8RnjkjLLR1hKM25IkjiFaxTfbU+50gOZ5ErnvIx0o0lrbfPy6Mu8a7ctdyzaaKoaNyKh7c36f4Lw8KB8jJbbAVlTSsTqDlBsyoXNJBXj5iQ97W70Y2F0okBViVBsV0/y+d33iYFEN25IAqHDSHJYnNvyK2qsfBTdLzNGSr/9ZjSQ6MZNSSC0VJBKGOyqJDc1verDOAdGqEuD73idRDduTALJ2WaFshh+JCIBL81W0XrDOUjm/1aaGZul/7dyPLUWAE1J/gP9kryefkleT78kr6f/h+Qt9BeZjd64ivLZHwAAAABJRU5ErkJggg==',
					getProps: (ctx) => {
						return {
							importe: ctx.total_cimp,
							publicKey: this.aplazamePublicKey
						}
					}
				}
			}

			let tipos = this.tipos ? this.tipos.toUpperCase().split(",") : []

			// Quitamos valores duplicados
			tipos = [...new Set(tipos)]

			return tipos.filter(t => disponibles[t]).map(t => {
				let tipo = disponibles[t]
				return {					
					cod: t,
					componentName: tipo.componentName,
					label: tipo.label,
					//component: () => import("@/components/Simuladores/" + tipo.componentName + ".vue"),
					getProps: tipo.getProps,
					contentStyle: tipo.contentStyle,
					logo: tipo.logo
				}
			})
		},
		context(){
			let result = {
				productos: this.data.productos,
				total_simp: 0,
				total_cimp: 0
			}

			if (this.data.productos.length == 0) return result

			this.data.productos.forEach(p => {
				p.importe_simp = p.importe_cimp / (1+(p.valor_impuesto/100))
				result.total_simp += p.importe_simp * p.unidades
				result.total_cimp += p.importe_cimp * p.unidades
			})

			return result
		}
	},
	mounted(){
		if (this.simuladores.length > 0) this.control.active_simulator = this.simuladores[0].cod
	},
	methods: {
		setProductos(productos){
			this.data.productos = productos
		},
		isActive(codsimulador){
			return this.control.active_simulator == codsimulador
		},
		toggle(codsimulador){
			let idx = this.control.active.findIndex(s => s == codsimulador)

			if (idx == -1) {
				this.control.active.push(codsimulador)
			} else {
				this.control.active.splice(idx, 1)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.i-simulator-wrapper{
	.i-simulator-title{
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: -.02em;
		color: #000;
		font-family: SF Pro Text;
		white-space: nowrap;
		margin-bottom: 16px;
	}

	.i-simulator-box{
		background: #f7f7f7;
    	border-radius: 8px;
	
		.i-simulator-menu{
			display:flex;
			border-bottom:2px solid #eee;
			padding: 0 10px;
			padding-top: 8px;

			.i-simulator-menu-option{
				padding:8px 0;
				margin: 0 6px;
				transform:translateY(2px);
				border-bottom: 2px solid transparent;
				opacity:0.8;
				transition:0.2s all ease;
		
				.i-simulator-logo{
					height:14px;
					filter:grayscale(1);
					transition:0.2s all ease;					
				}

				&:hover{
					cursor:pointer;	
					border-color: #aaa;
					opacity:1;
				}

				&.active{
					border-color: #2a2a2a;
					transition:0.2s all ease;
					opacity:1;

					.i-simulator-logo{
						filter:grayscale(0);						
					}
				}
			}
		}

		.i-simulator-content{
			padding:16px;
			font-size:12px;
			line-height:16px;
		}
	
		.i-simulator{
			border-bottom:1px solid #e0e0e0;
		
			.i-simulator-content{
				display:none;
			}
			
			&:last-child{
				border-bottom: none;
			}

			.i-simulator-header{
				cursor:pointer;
				padding: 16px;
				display:flex;
				align-items:center;

				.i-simulator-logo{
					display:block;
					height:14px;
				}

				.i-simulator-title{
					flex:1;
					font-size:12px;
					line-height:16px;
				}
			}

			.i-simulator-content{
				padding:8px 16px;
				padding-bottom:0;
				font-size:13px;
				line-height:16px;
			}
		}
	}
}
</style>

<style lang="scss">

</style>