<template>
	<button class="chat-launcher" :class="{'visible': loaded && !opened}" @click="launchChat">
		<icon icon="comment" variant="regular"/>
		<span class="chat-launcher-label">{{$msg('1')}}</span>
	</button>
</template>

<script>
import Icon from '@/components/Icon'
import i18n from '@/mixins/i18n'

export default {
	props: ['apiKey', 'nombre', 'email'],
	components: {Icon},
	mixins: [i18n],
	data() {
		return {
			categoryConsent: "statistics",
			loaded: false,
			opened: false,
			dict: {
				es: {
					"1": "¿Te ayudamos?",
					"2": "Para contactarnos por chat es necesario que aceptes el consentimiento para las <b>Cookies estadísticas</b>."
				},
				ca: {
					"1": "T'ajudem?",
					"2": "Per contactar-nos a través del xat és necessari que acceptis el consentiment per les <b>Cookies estadístiques</b>"
				}
			}
		}
	},
	mounted(){
		setTimeout(() => this.loaded = true, 500)

		//if(this.$consentManager.granted(this.categoryConsent)){
		setTimeout(() => {
			this.loadScript()
		}, 1)
		//}
	},
	methods: {
		async launchChat(){
			//let bConsentGranted = await this.$consentManager.askForGrant(this.categoryConsent, this.$msg("2"))
			//if(bConsentGranted){
				if (!document.getElementById("ze-snippet")) {
					await this.loadScript()
				}

				zE('webWidget', 'show')
				zE('webWidget', 'open')
			//}
		},
		async loadScript(){
			let me = this
			return new Promise((resolve, reject) => {
				let script = document.createElement('script');
				script.type = 'text/javascript';
				script.id = 'ze-snippet';
				script.async = true;
				script.src = 'https://static.zdassets.com/ekr/snippet.js?key=' + this.apiKey;
				script.onload = () => {
					zE('webWidget', 'hide');
					zE('webWidget', 'setLocale', 'es');

					if(this.email){
						zE('webWidget', 'identify', {
							name: this.nombre || "",
							email: this.email
						})
					}
					
					zE('webWidget:on', 'chat:status', status => {
						switch(status.toLowerCase()){
							case "available":
							case "online":
								//this.$store.commit("SET_CHAT_AVAILABLE")
								document.body.classList.add("chat-available")
								break
							default:
								//this.$store.commit("SET_CHAT_UNAVAILABLE")
								document.body.classList.remove("chat-available")
								break
						}
					})

					zE('webWidget:on', 'chat:unreadMessages', () => {
						zE('webWidget', 'show')
						zE('webWidget', 'open')
						//this.$store.commit("SET_CHAT_WINDOW_OPENED")
						me.opened = true
					})

					zE('webWidget:on', 'open', () => {
						//this.$store.commit("SET_CHAT_WINDOW_OPENED")
						me.opened = true
					})	

					zE('webWidget:on', 'close', () => {
						//this.$store.commit("SET_CHAT_WINDOW_CLOSED")
						zE('webWidget', 'hide');
						me.opened = false
					})

					zE('webWidget:on', 'chat:popout', () => {
						//this.$store.commit("SET_CHAT_WINDOW_CLOSED")
						me.opened = false
						zE('webWidget', 'hide');
					})
					return resolve(true)
				}
				document.getElementsByTagName('head')[0].appendChild(script);
			})
		}
	}
}
</script>

<style lang="scss" scoped>
/*.chat-launcher{
	border:none;
	display:flex;
	outline:none;
	padding:10px 20px;
	z-index:9999 !important;
	position:fixed;
	bottom:-50px;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	right:20px;
	width:auto;
	height:38px;
	font-size:15px;
	font-family: SF Pro Text, Helvetica, Arial, sans-serif;
	transition:0.4s all ease-in-out;
	opacity:0;
	background: #10A66A;
	color:white;
	align-items:center;
		
	&.visible{
		bottom:0px;
		opacity:0.9;
	}

	&:hover{
		opacity:1;
		border-color:#555;
		cursor:pointer;
	}

	.icon{
		position:relative;
		top:1px;
		font-size:20px;
		margin-right:10px;
	}

	.chat-launcher-label{
		font-size:12px;
	}
}*/

/*.chat-launcher{
	position:fixed;
	top:50%;
	left:20px;
	transform: translateY(-50%);
	z-index:9999 !important;
	border:none;	
	outline:none;
	text-align:center;
	border-radius:50px;
	width:50px;
	height:50px;
	font-size:15px;
	display:grid;
	place-items:center;
	font-family: SF Pro Text, Helvetica, Arial, sans-serif;
	transition:0.3s all ease-in-out;
	opacity:0;
	background: #10A66A;
	color:white;
	animation: i-pulse-animation 2s infinite;
		
	&.visible{
		bottom:0px;
		opacity:0.8;
	}

	&:hover{
		opacity:1;
		border-color:#555;
		cursor:pointer;
	}

	.icon{
		position:relative;
		top:1px;
		font-size:22px;
		//margin-right:10px;
	}

	.chat-launcher-label{
		font-size:12px;
	}
}*/

.chat-launcher{
	position:fixed;
	//bottom:180px;
	top: 50%;
	transform: translate3d(0,-50%,0);
	right:0px;
	z-index:500;
	border:none;	
	outline:none;
	text-align:center;
	border-top-left-radius:50px;
	border-bottom-left-radius:50px;
	width:50px;
	height:50px;
	font-size:15px;
	display:flex;
	gap:10px;
	align-items:center;
	/*display:grid;
	place-items:center;*/
	padding-left:16px;
	//font-family: SF Pro Text, Helvetica, Arial, sans-serif;
	transition:0.3s all ease-in-out;
	opacity:0;
	background: #8ABB24;
	color:white;
	animation: i-pulse-animation 2s infinite;
		
	&.visible{
		//transform:translate(0,0,0);
		opacity:0.8;
	}

	&:hover{
		opacity:1;
		border-color:#555;
		cursor:pointer;
	}

	.icon{
		position:relative;
		top:1px;
		font-size:22px;
		display:inline-block;
		//margin-right:10px;
	}

	.chat-launcher-label{
		display:inline-block;
		font-size:12px;
		opacity:0;
		white-space:nowrap;
		transition:0.3s all ease-in-out;
		transform: translate3d(20px, 0, 0);
	}
}

@media (hover: hover) {
  .chat-launcher:hover {
    width:150px;

	.chat-launcher-label{
		opacity:1;
		transform: translate3d(0, 0, 0);
	}
  }
}
</style>

<style lang="scss">
@keyframes i-pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.25);
  }
  100% {
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0);
  }
}
</style>