<template>
	<div>
		<iframe ref="iframe" :srcdoc="srcDoc" v-show="validAmount" v-if="srcDoc"/>
		<div v-show="!validAmount">No es posible financiar importes inferiores a {{formatCurrency(min)}}.</div>
	</div>
</template>

<script>
import utils from '@/mixins/utils'

export default {
	props: ['importe', 'min'],
	mixins: [utils],
	data(){
		return {
			srcDoc: null,
			cantidad: this.importe,
			num_recalc: 0
		}
	},
	created(){	
		setTimeout(() => {
			this.init()
		}, 1)
	},
	watch:{
		importe(){
			this.cantidad = this.importe
			this.$nextTick(() => this.update())
		}
	},
	computed: {
		validAmount(){
			if (!this.cantidad) return false
			if (!this.min) return true

			let min = parseFloat(this.min)
			return this.cantidad >= min			
		}
	},
	methods: {
		init(){
			window.addEventListener("message", this.onMessage)
			//document.addEventListener("product-updateSelection", this.onUpdateProduct.bind(this))	
			this.updateSrcDoc()
		},
		updateSrcDoc(){
			let cantidad = parseFloat(this.cantidad+"").toFixed(2)
			let codCentro = 'INTECATONLINE'
			let cuotaPago = 'PC'
			let server = "https://www.cetelem.es"
			let urlCalculadora = "https://www.cetelem.es/eCommerceCalculadora/resources/js/eCalculadoraCetelemCombo.js"
			let material = ""

			if(!this.srcDoc) {
				this.srcDoc = `
					<html>
						<head>
							<script src="https://code.jquery.com/jquery-3.7.1.min.js"><\/script>
							<script src="https://cdn.jsdelivr.net/npm/resize-observer-polyfill@1.5.1"><\/script>
							<style>
								body {
									background-color: transparent;
									padding: 0;
									margin: 0;
								}

								.encabezado-enc{
									display:none !important;
								}

								.calculadora-cetelem-wrapper #eCalculadoraCetelemDiv .eCalculadoraCetelem-combo .calc-container-enc{
									background: none !important;
									border:none !important;
									height: auto !important;
									margin-bottom: 12px !important;
								}

								.calculadora-cetelem-wrapper #eCalculadoraCetelemDiv .eCalculadoraCetelem-combo .calc-content-enc{
									justify-content: start !important;
									margin: 0 !important;
								}

								.calculadora-cetelem-wrapper label {
									font-family: "Verdana" !important;
									margin-top: 0px !important;
									font-size: 12px !important;
									font-weight: 500 !important;
									margin-bottom: 10px !important;
								}

								.calculadora-cetelem-wrapper .cantidad {
									margin-top: 10px !important;
									border-radius: 4px !important;
									background: #f5f5f5;
									text-align: Center;
									height: 25px !important;
									padding-top: 2px !important;
									font-weight: normal;
									font-family: "Verdana", "Trebuchet MS", "Arial", "Helvetica", "sans-serif" !important;
									font-size: 16px;
									color: #626262;
								}

								.calculadora-cetelem-wrapper p {
									font-size: 14px !important;
									font-weight: 500 !important;
									line-height: 20px !important;
								}

								.calculadora-cetelem-wrapper .eCalculadoraCetelem {
									padding: 0 !important;
									margin: 0 !important;
									width: 100%;
									display: grid;
									grid-template-columns: 1fr 1fr;
									grid-gap: 10px;
								}

								.calculadora-cetelem-wrapper .eCalculadoraCetelem>* {
									grid-column: span 2;
								}

								.calculadora-cetelem-wrapper .eCalculadoraCetelem .contExterno2_financiacionCtl,
								.calculadora-cetelem-wrapper .eCalculadoraCetelem .contExterno2_importeMensualidadCtl {
									width: 100%;
									grid-column: span 1;
									padding: 0;
									margin: 0;
								}

								.calculadora-cetelem-wrapper .eCalculadoraCetelem .contExterno2_financiacionCtl .cont_financiacionCtl {
									padding-top: 0 !important;
									height: auto !important;
								}

								.calculadora-cetelem-wrapper .eCalculadoraCetelem .contExterno2_financiacionCtl .cont_financiacionCtl .cont_select_financiacionCtl {
									display: inline-block;
									float: none;
									width: 100%;
									margin: 0;
									padding: 0;
									clear: both;
									max-width: 100%;
								}

								.calculadora-cetelem-wrapper .eCalculadoraCetelem .contExterno2_financiacionCtl .cont_financiacionCtl .cont_select_financiacionCtl #financiacionCtl {
									border: 1px solid #aaa !important;
									border-radius: 6px !important;
									padding: 10px !important;
									height: 35px;
								}

								.calculadora-cetelem-wrapper .eCalculadoraCetelem .contExterno2_financiacionCtl .cont_financiacionCtl .smallTextCtl {
									display: none !important;
								}

								.calculadora-cetelem-wrapper .eCalculadoraCetelem .contExterno2_importeMensualidadCtl .cont_importeMensualidadCtl {
									padding-top: 10px !important;
									padding-bottom: 10px !important;
									font-weight: bold !important;
									display: block !important;
									border-radius: 6px !important;
									background: #f5f6f7 !important;
								}

								.calculadora-cetelem-wrapper .eCalculadoraCetelem .contExterno2_importeMensualidadCtl .cont_importeMensualidadCtl #divSinInteres {
									margin-top: 10px !important;
								}

								.calculadora-cetelem-wrapper .eCalculadoraCetelem #infoFinanciacionCtl {
									margin-top: 0px !important;
									padding-top: 0 !important;
								}

								.calculadora-cetelem-wrapper #bloqueFinanciacionCtlImporte {
									display: none !important;
								}

								.calculadora-cetelem-wrapper.importe-modificable #bloqueFinanciacionCtlImporte {
									display: block !important;
								}

								@media screen and (max-width: 340px) {

									.calculadora-cetelem-wrapper .eCalculadoraCetelem .contExterno2_financiacionCtl,
									.calculadora-cetelem-wrapper .eCalculadoraCetelem .contExterno2_importeMensualidadCtl {
										grid-column: span 2;
									}
								}
							</style>
						</head>

						<body>
							<div class="calculadora-cetelem-wrapper">
								<div id="eCalculadoraCetelemDiv" />
							</div>
						</body>

						<script>
							const resizeObserver = new ResizeObserver(entries => {
								// ATENCION! Se espera que sólo se observe 1 elemento que se corresponde con el DIV de la Calculadora
								let entry = entries[0]
								window.parent.postMessage({
									type: "cetelem-resize",
									payload: {
										width: entry.contentRect.width,
										height: entry.contentRect.height
									}
								}, "*")
							})

							let divCalculadora = document.getElementById("eCalculadoraCetelemDiv")
							resizeObserver.observe(divCalculadora)

							window.addEventListener("message", (msg) => {
								if (!msg.data || !msg.data.type) return

								switch (msg.data.type) {
									case "recalc":
										window.cantidad = msg.data.payload.cantidad
										window.material = msg.data.payload.material || ''
										window.construyeCalculadora(jQuery)
										break
								}
							})

							window.color = "#000000"
							window.colorFondo = "#fff"
							window.fontSize = "10"

							const urlParams = new URLSearchParams(window.location.search)
							window.cantidad = "${cantidad}"
							window.codCentro = "${codCentro}"
							window.cuotaPago = "${cuotaPago}"
							window.server = "${server}"
							window.bloquearImporte = false
							window.material = "${material}"

							window.cetelem_proxy = null
							window.cetelem_orig = null
							window.cetelem_requests = 0

							let me = this
							Object.defineProperty(window, 'cetelem', {
								get() {
									return window.cetelem_proxy
								},
								set(fn) {
									if (fn) {
										window.cetelem_orig = fn
										window.cetelem_requests = 0

										window.cetelem_proxy = (data) => {
											if (window.cetelem_requests < 2) {
												window.cetelem_requests++

												let datos = data.datosCalculadora
												if (datos) {
													let comboFinanciacion = document.getElementById("financiacionCtl-combo")

													let cuotas = []
													for (let i = 0; i < comboFinanciacion.options.length; i++) {
														cuotas.push(parseInt(comboFinanciacion.options[i].value.split(";")[0]))
													}
													cuotas.sort((a, b) => a - b)

													//if (comboFinanciacion.selectedIndex == comboFinanciacion.options.length-1 && datos.numCuotas) {
													if (datos.numCuotas) {
														// solo se eleva el calculo para la opcion de maximas cuotas (ultimo elemento del combo seleccionado)
														window.parent.postMessage({
															type: "cetelem-result",
															payload: {
																num_cuotas: datos.numCuotas,
																cuotas: cuotas,
																cuota_mensual: datos.cuotaMensual,
																tin: datos.tin,
																tae: datos.tae,
																importe_financiado: datos.importeFinanciado,
																importe_adeudado: datos.importeTotalAdeudado
															}
														}, "*")
													}
												}
												window.cetelem_orig(data)
											} else {
												window.cetelem = null
											}
										}
									} else {
										window.cetelem_proxy = null
										window.cetelem_orig = null
									}
								}
							})

							let script = document.createElement('script')
							script.async = false
							script.setAttribute('type', 'text/javascript')
							script.setAttribute('src', "${urlCalculadora}")
							document.head.appendChild(script)
						<\/script>

						<style>
							body #eCalculadoraCetelemDiv .eCalculadoraCetelem-combo .calculadora-enc{
								max-width:100% !important;
							}

							body #eCalculadoraCetelemDiv .container-loader {
								width: 100% !important;
							}

							body #eCalculadoraCetelemDiv .eCalculadoraCetelem-combo .encabezado-enc{
								text-align:left !important;
							}
						</style>
					</html>
				`			
			}else{
				let iframeWin = this.$refs.iframe.contentWindow
				iframeWin.cantidad = cantidad
				iframeWin.construyeCalculadora(iframeWin.$)
			}
		},
		/*onUpdateProduct(e){
			this.cantidad = (e.detail.price/100).toFixed(2)
			this.update()
		},*/
		update(){
			this.updateSrcDoc()

			this.$nextTick(() => {
				this.$emit("start-update")
				this.$refs.iframe.contentWindow.postMessage({type: "cetelem-recalc", payload: {cantidad: parseFloat(this.cantidad+"").toFixed(2), material: this.material}})
				this.num_recalc++
			})
		},
		onMessage(msg){
			if (!msg.data || !msg.data.type) return
			
			switch(msg.data.type){
				case "cetelem-result":
					this.$emit("end-update")
					this.$emit("update", msg.data.payload)
					break
				case "cetelem-resize":
					if (this.$refs.iframe) this.$refs.iframe.style.height = msg.data.payload.height + "px"
					break
			}
		}
	}
}
</script>

<style lang="scss" scoped>
iframe{
	border: none;
	width: 100%;
	height:0;
	transition:0.2s height ease;
	overflow-x: hidden;
}
</style>

<style lang="scss">

</style>