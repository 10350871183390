<template>
	<div class="calculadora-iberent">		
		<iframe ref="iframe" :srcdoc="data.html" v-if="data.html" @load="onload" v-show="control.visible"/> <!-- v-resize="{ log: true }"-->
	</div>
</template>

<script>
import utils from '@/mixins/utils'

/*import Vue from 'vue'
import iframeResize from 'iframe-resizer/js/iframeResizer';

Vue.directive('resize', {
  bind: function(el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el))
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  }
})
*/

export default {
	props: ['productos', 'url-api'],
	mixins: [utils],
	data(){
		this.$api.init(this.urlApi)

		return {
			data: {
				cantidad: null,
				html: null
			},
			control: {
				visible: false
			}
		}
	},
	watch: {
		productos: {
			deep: true,
			handler(){
				this.$emit("start-update")
				this.$nextTick(() => {
					this.refresh()
				})
			}
		}
	},
	mounted(){
		window.addEventListener("message", this.onMessage.bind(this))
		setTimeout(() => {
			this.refresh()
		}, 1)		
	},
	methods: {
		onload(){
			setTimeout(() => {
				this.control.visible = true
			}, 100)
		},
		async refresh(){
			let data = await this.getData()

			if(data){
				// Añadimos un disparador que nos lance el resultado del cálculo del iframe al padre
				let html = data.html.replace(/(calculate_callback.*?)(return true;)/gms, "$1window.parent.postMessage({type: 'iberent-recalc', data: response}, '*');return true;")				

				// Lógica para cazar el cambio de tamaño de la calculadora
				let resizeScript = `
					<style>
						html, body{
							margin:0;
							padding:0;
						}
					</style>
					<script src="https://cdn.jsdelivr.net/npm/resize-observer-polyfill@1.5.1"><\/script>
					<script>
						const resizeObserver = new ResizeObserver(entries => {
							// ATENCION! Se espera que sólo se observe 1 elemento que se corresponde con el DIV de la Calculadora
							let entry = entries[0]
							window.parent.postMessage({
								type: "iberent-resize",
								payload: {
									width: entry.contentRect.width,
									height: entry.contentRect.height
								}
							}, "*")
						})

						let divCalculadora = document.getElementById("ibe-widget")
						resizeObserver.observe(divCalculadora)
					<\/script>
				`
			
				// Añadimos el control de tamaño del iframe
				html += resizeScript

				this.data.html = html
			}
		},
		async getData(){
			let total = 0
			let items = []

			this.productos.forEach(p => {
				let importe_unitario_simp = p.importe_simp
				let importe_total_simp = (importe_unitario_simp * p.unidades)

				total += importe_total_simp
				
				//if(p.idproducto){
					items.push({
						quantity: p.unidades,
						unit_price: importe_unitario_simp.rnd(2),
						discount_percent: 0,
						total_price: importe_total_simp.rnd(2),
						product: {
							sku_number: p.sku,
							part_number: p.sku,
							model: p.descripcion,
							description: p.descripcion,
							brand: p.marca,
							category: p.categoria
						}
					})
				//}
			})

			this.data.cantidad = total
			return await this.$api.iberent.simular({type: 'product', amount: total.rnd(2), items: items})			
		},
		emitCurrent(data){
			this.$emit("end-update")
			this.$emit("update", {
				num_cuotas: data.duration,
				cuota_mensual: data.payment,
				cuota_mensual_original: data.meta && data.meta.original_price ? data.meta.original_price : null,
				tin: 0,
				tae: 0,
				importe_financiado: data.amount,
				importe_adeudado: (data.duration * data.payment)				
			})
		},
		onMessage(msg){
			if (!msg.data || !msg.data.type) return
			
			switch(msg.data.type){
				case "iberent-recalc":
					this.emitCurrent(msg.data.data.result)
					break
				case "iberent-resize":
					if (this.$refs.iframe) this.$refs.iframe.style.height = msg.data.payload.height + "px"
					break
			}			
		}
	}
}
</script>

<style lang="scss" scoped>
iframe {
	border: none;
	width: 100%;
	height: 200px;
}
</style>