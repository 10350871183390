import KDSolicitud from './kd_solicitud'

export default class KDSolicitudes {
	constructor(deps){
		this.deps = deps
		this.axios = this.deps.axios
		this.api = this.deps.api
	}

	get(codigo){
		return new KDSolicitud(codigo, this.deps)
	}

	new(){
		return new KDSolicitud(null, this.deps)
	}

	async getDispositivos(params) {
		return await this.api.post('kd-solicitudes/getDispositivos', params)		
	}
}