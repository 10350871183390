import axios from 'axios'

import CCDs from './ccds'
import Contactos from './contactos'
import Formularios from './formularios'
import Iberent from './iberent'
import Reservas from './reservas'
import Sorteos from './sorteos'
import Suscripciones from './suscripciones'
import Productos from './productos'

class PLNError extends Error {
	constructor (data, params) {
		super()
		
		if (typeof Error.captureStackTrace === 'function') {
			Error.captureStackTrace(this, this.constructor);
		} else { 
			this.stack = (new Error(data.message)).stack; 
		}
		
		this.name = 'PLNError'
		this.code = data.code
		this.message = data.message
	}
}

export default class API {
	constructor(deps){
		this.deps = deps
		this.axios = null
		this.deps.api = this

		this.ccds = new CCDs(this.deps)
		this.contactos = new Contactos(this.deps)	
		this.formularios = new Formularios(this.deps)
		this.iberent = new Iberent(this.deps)
		this.productos = new Productos(this.deps)
		this.reservas = new Reservas(this.deps)
		this.sorteos = new Sorteos(this.deps)
		this.suscripciones = new Suscripciones(this.deps)			
	}

	init(url){
		this.axios = axios.create({
			baseURL: url,		
		})

		this.axios.interceptors.response.use(function (response) {
			if(response.data.error){
				let endpoint = response.config.url.replace(response.config.baseURL, "")
		
				let e = {
					code: response.data.error.code,
					message: '<b>' + endpoint + "</b><br/>" + response.data.error.message
				}
				
				throw new PLNError(e)
			}

			return response
		})
	}

	async post(endpoint, params){
		let res = await this.axios.post(endpoint, params, {withCredentials: true})
		return res.data
	}
}