<template>
	<div class="i-contacto">
		<transition name="fade">
			<div v-show="control.bfetched && !control.bsent">
				<el-form ref="contacto" :model="data" :rules="validation_rules" :show-message="true" label-position="top" >
					<el-form-item prop="codarea" :label="label_area" v-if="!ocultarArea">
						<el-select v-model="data.codarea" :placeholder="$msg('selecciona')" class="fw" @change="onChangeArea" filterable default-first-option>
							<el-option v-for="area in aux.areas" :key="area.codarea" :value="area.codarea" :label="area.nombre"/>
						</el-select>
					</el-form-item>

					<div v-show="data.codarea">
						<el-form-item prop="codtema" :label="label_tema" v-if="btiene_temas && !ocultarTema">
							<el-select v-model="data.codtema" :placeholder="$msg('selecciona')" class="fw" filterable default-first-option>
								<el-option v-for="tema in temas" :key="tema.codtema" :value="tema.codtema" :label="tema.nombre"/>
							</el-select>
						</el-form-item>

						<!--div v-show="!btiene_temas || (data.codarea && data.codtema)"-->
						<div>
							<el-row :gutter="20">
								<el-col :xs="12" :sm="12" :md="6">
									<el-form-item prop="nombre" :label="$msg('1')">
										<el-input type="text" v-model="data.nombre" maxlength="100"/>
									</el-form-item>
								</el-col>
								<el-col :xs="12" :sm="12" :md="6">
									<el-form-item prop="apellidos" :label="$msg('2')">
										<el-input type="text" v-model="data.apellidos" maxlength="100"/>
									</el-form-item>
								</el-col>
								<el-col :xs="12" :sm="12" :md="6">
									<el-form-item prop="email" :label="$msg('3')">
										<el-input type="text" v-model="data.email" maxlength="255"/>
									</el-form-item>
								</el-col>
								<el-col :xs="12" :sm="12" :md="6">
									<el-form-item prop="telefono" :label="$msg('4')">
										<el-input type="text" v-model="data.telefono" maxlength="15"/>
									</el-form-item>
								</el-col>			
							</el-row>
							
							<el-form-item prop="datos.mensaje" :label="ayuda">
								<el-input type="textarea" v-model="data.datos.mensaje" :rows="6"/>
							</el-form-item>	
							
							<el-upload ref="uploader" class="upload-wrapper" action="" :file-list="control.file_list" drag accept="image/*, .pdf, .docx, .doc" v-show="banexos" :on-change="onChangeFile" :on-remove="onRemoveFile" :before-upload="onBeforeUpload" :auto-upload="false">
								<div>
									<icon icon="cloud-upload" variant="light"/>
									<div class="el-upload__text" v-html="$msg('5')"></div>
								</div>
							</el-upload>

							<!--el-form-item prop="bnewsletter">
								<el-checkbox v-model="data.bnewsletter">Acepto recibir comunicaciones comerciales, promociones y eventos exclusivos de INTECAT.</el-checkbox>
							</el-form-item-->

							<el-form-item prop="bpolitica_privacidad">
								<el-checkbox v-model="data.bpolitica_privacidad"><div v-html="$msg('6', {url_privacidad: urlPrivacidad})"></div></el-checkbox>
							</el-form-item>
							
							<div class="actions" style="margin-bottom:20px">
								<el-button type="primary" round size="large" @click="save">{{$msg('7')}}</el-button>
							</div>

							<div class="rgpd">
								<p class="text-center" style="margin-bottom:5px;"><b>{{$msg('rgpd.1')}}</b></p>
								<p><b>{{$msg('rgpd.2')}}:</b> {{$msg('rgpd.3')}}</p>
								<p><b>{{$msg('rgpd.4')}}:</b> {{$msg('rgpd.5')}}</p>
								<p><b>{{$msg('rgpd.6')}}:</b> <span v-html="$msg('rgpd.7')"></span></p>
								<p><b>{{$msg('rgpd.8')}}:</b> <span v-html="$msg('rgpd.9', {url_privacidad: urlPrivacidad})"></span></p>
							</div>
						</div>
					</div>
				</el-form>
			</div>
		</transition>
		<transition name="fade">
			<div class="confirmation" v-show="control.bsent">
				<p>
					<b>{{$msg('8', {nombre: data.nombre})}}.</b>
					<br><br>
					{{$msg('9')}}
					<br><br>
					{{$msg('10')}}
				</p>
			</div>
		</transition>
	</div>
</template>

<script>
import i18n from '@/mixins/i18n'
import Icon from '@/components/Icon'

export default {
	props: {
		"codarea": {type: String, default: null},
		"url-api": {type: String, default: null},
		"url-privacidad": {type: String, default: null},
		"codtema": {type: String, default: null},
		"ocultar-area": {type: Boolean, default: false},
		"ocultar-tema": {type: Boolean, default: false},
		"label-area": {type: String, default: null},
		"label-tema": {type: String, default: null},
		"usuario-nombre": {type: String, default: null},
		"usuario-apellidos": {type: String, default: null},
		"usuario-email": {type: String, default: null},
		"usuario-tlf": {type: String, default: null}
	},
	mixins: [i18n],
	components: {Icon},
	data() {
		//this.loadi18nMessages('component', 'contacto')
		this.$api.init(this.urlApi)

		return {
			data: {
				codarea: this.codarea || null,
				codtema: this.codtema || null,
				nombre: this.usuarioNombre,
				apellidos: this.usuarioApellidos,
				email: this.usuarioEmail,
				telefono: this.usuarioTlf,
				bpolitica_privacidad: false,
				bnewsletter: false,
				datos: {
					mensaje: null
				},
				anexos: []
			},
			aux: {
				areas: []
			},
			control: {
				bfetched: false,
				file_list: [],
				bsent: false
			},
			dict: {
				es: {
					"1": "Nombre",
					"2": "Apellidos",
					"3": "Correo electrónico",
					"4": "Teléfono (opcional)",
					"5": "Arrastra aquí los ficheros<br>o <em>pulsa aquí</em> para adjuntar tus anexos",
					"6": "He leído y acepto la <a href='{url_privacidad}' target='_blank'>política de privacidad</a>",
					"7": "Enviar mensaje",
					"8": "Muchas gracias por contactar con nosotros, {nombre}",
					"9": "Te acabamos de enviar una copia de tu mensaje a tu correo electrónico confirmando que hemos recibido tu mensaje. Trataremos de responderte con la mayor brevedad posible.",
					"10": "Recibe un cordial saludo.",
					"11": "¿Con qué departamento quieres contactar?",
					"12": "¿Qué necesitas?",
					"13": "Describe brevemente el motivo de tu consulta",
					"selecciona": "Selecciona...",
					"rgpd": {
						"1": "INFORMACIÓN SOBRE PROTECCIÓN DE DATOS",
						"2": "Responsable",
						"3": "INTECAT iSTORE S.L.",
						"4": "Finalidades",
						"5": "Gestionar la entrega del producto, su factura y el servicio postventa así como el envío de comunicaciones con ofertas y servicios compatibles con el producto comprado, campañas, eventos y actividades por correo electrónico.",
						"6": "Derechos",
						"7": "Puede acceder, rectificar y suprimir los datos en <a href='mailto:info@intecat.com'>info@intecat.com</a>.",
						"8": "Información adicional",
						"9": "Puede consultarla en nuestra página de <a href='{url_privacidad}' target='_blank'>política de privacidad</a>."
					},
					"validacion": {
						"1": "Campo obligatorio",
						"2": "Es necesario aceptar la política de privacidad"
					}
				},
				ca: {
					"1": "Nom",
					"2": "Cognoms",
					"3": "Correu electrònic",
					"4": "Telèfon (opcional)",
					"5": "Arrossega aquí els fitxers<br>o <em>prem aquí</em> per a adjuntar els teus arxius",
					"6": "He llegit i accepto la <a href='{url_privacidad}' target='_blank'>política de privacitat</a>",
					"7": "Enviar missatge",
					"8": "Moltes gràcies per contactar amb nosaltres, {nombre}",
					"9": "T'acabem d'enviar una còpia del teu missatge al teu correu electrònic confirmant que hem rebut el teu missatge. Tractarem de contestar-te al més aviat possible.",
					"10": "Rep una cordial salutació.",
					"11": "Amb quin departament vols contactar?",
					"12": "Què necessites?",
					"13": "Descriu breument el motiu de la teva consulta",
					"selecciona": "Selecciona...",
					"rgpd": {
						"1": "INFORMACIÓ SOBRE PROTECCIÓ DE DADES",
						"2": "Responsable",
						"3": "INTECAT iSTORE S.L.",
						"4": "Finalitats",
						"5": "Gestionar el lliurament del producte, la seva factura i el servei postvenda així com l'enviament de comunicacions amb ofertes i serveis compatibles amb el producte comprat, campanyes, esdeveniments i activitats per correu electrònic.",
						"6": "Drets",
						"7": "Pot accedir, rectificar i suprimir les dades en <a href='mailto:info@intecat.com'>info@intecat.com</a>.",
						"8": "Informació addicional",
						"9": "Pot consultar-la a la nostra pàgina de <a href='{url_privacidad}' target='_blank'>política de privacitat</a>."
					},
					"validacion": {
						"1": "Camp obligatori",
						"2": "És necessari acceptar la política de privacitat"
					}
				}
			}			
		}
	},
	methods: {
		async fetchAreas(){
			this.aux.areas = await this.$api.contactos.getAreas()
			this.control.bfetched = true
		},
		onRemoveFile(){
			this.data.anexos = []
		},
		onBeforeUpload(file){
			this.data.anexos = []
			//this.$refs.clearFiles()
			//this.refs.uploader.clearFiles()
			return true
		},
		onChangeFile(file, fileList){
			/*  if (file.size > this.maxSize) {
                fileList.splice(-1)
                return
            }else{*/
			if(fileList.length > 1){
				fileList.shift()
				this.control.file_list = fileList
			}
				
			let me = this
			let reader = new FileReader()
			reader.readAsDataURL(file.raw);
			reader.onload = function () {
				me.data.anexos = []
				me.data.anexos.push({
					filename: file.name,
					content: reader.result.split(',')[1]
				})
			};
			reader.onerror = function (error) {
				console.log('Error: ', error)
			}
		},
		onChangeArea(){
			this.data.codtema = null
			this.data.anexos = []
			this.$refs.uploader.clearFiles()
		},
		async save(){
			let valid = await this.validate()
			if(valid){
				try{
					await this.$api.contactos.set(this.data)
					this.control.bsent = true
				}catch(e){
					console.log(e)
				}
			}
		},
		checkPrivacidad(rule, value, callback){
			if(value) callback()
			else callback(new Error(this.$msg('validacion.2')))
		},
		validate_boolean(rule, value, callback){
			if (value) callback()
			else callback(new Error(this.$msg('validacion.1')))
		},
		async validate(){
			let form = this.$refs['contacto']
			
			return new Promise((resolve, reject) =>{
				this.$nextTick(() => {
					form.validate((valid, obj)=> {
						return resolve(valid)
					})
				})
			})
		},
	},
	mounted(){
		this.fetchAreas()
	},
	computed: {
		validation_rules(){
			return {
				codarea: {required:true, message: this.$msg('validacion.1'), trigger:"submit"},
				codtema: {required:true, message: this.$msg('validacion.1'), trigger:"submit"},
				nombre: {required: true, message: this.$msg('validacion.1'), trigger:"submit"},
				apellidos: {required: true, message: this.$msg('validacion.1'), trigger:"submit"},
				email: {required: true, type:"email", message: this.$msg('validacion.1'), trigger:"submit"},
				//telefono: {required: true, message: this.$msg('validacion.1'), trigger:"submit"},				
				"datos.mensaje": {required: true, message: this.$msg('validacion.1'), trigger:"submit"},
				bpolitica_privacidad: {required: true, validator: this.checkPrivacidad, trigger:"submit"},
				//bnewsletter: {required: true, validator: this.validate_boolean, trigger:"submit"}
			}
		},
		label_area(){
			return this.labelArea || this.$msg('11')
		},
		label_tema(){
			return this.labelTema || this.$msg('12')
		},
		area_actual(){
			return this.aux.areas.find(a => a.codarea == this.data.codarea)
		},
		temas(){
			if(this.area_actual){
				return this.area_actual.temas
			}else{
				return null
			}
		},
		btiene_temas(){
			if(this.area_actual){
				return ((this.area_actual.temas && this.area_actual.temas.length > 0) ? true : false)
			}else{
				return false
			}
		},
		tema_actual(){
			if(this.temas){
				return this.temas.find(t => t.codtema == this.data.codtema)
			}
		},
		ayuda(){
			let ayuda = null
			if(this.area_actual){
				ayuda = this.area_actual.ayuda
				if(this.tema_actual && this.tema_actual.ayuda){
					ayuda = this.tema_actual.ayuda
				}
			}
			
			return ayuda || this.$msg('13')

		},
		banexos(){
			let banexos = false
			if(this.area_actual){
				banexos = this.area_actual.banexos
				if(this.tema_actual && this.tema_actual.banexos){
					banexos = this.tema_actual.banexos
				}
			}
			
			return banexos
		}
	}
}
</script>

<style lang="scss" scoped>
	.actions{
		text-align:right;
		margin-top:20px;
	}

	.fade-enter-active {
		transition: all 0.5s ease;
	}
	.fade-leave-active {
		position: absolute;
		transition: all 0.5s ease;
	}
	.fade-enter, .fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */ {
		transform: translateY(-10px);
		opacity: 0;
	}

	.confirmation{
		padding:30px;
		background: #dff0d8;
		border-radius:10px;
		font-weight:300;
		color:#3c763d;

		p{
			margin:0;
			color:#3c763d;
		}
	}

	.fw{
		display:block;
	}

	.rgpd{
		padding:20px;
		/*background: rgba(255,255,255,0.3);*/
		background: #fafafa;
		border:none;
		p{
			font-size:10.5px !important;
			margin-bottom:2px !important;			
			opacity:0.7;

			b{
				color: black !important;
			}
		}		
	}
</style>

<style lang="scss">
.i-contacto{
	a{
		color: #0071e3 !important;
	}

	.el-upload{
		display:block !important;

		.el-upload-dragger{
			width: 100% !important;
			display: grid;
    		place-items: center;
		}
	}	
}
</style>
