<template>
	<div class="ccd" v-if="control.definicion">
		<transition-group name="fade">
			<div v-show="control.status == 'unsaved'" key="form">
				<formulario :id="control.definicion.idformulario" :submit="submit" :url-api="urlApi" :codidioma="codidioma"/>
				<div class="rgpd" v-if="control.definicion.rgpd" v-html="control.definicion.rgpd[codidioma]"/>
			</div>
			<div v-show="control.status != 'unsaved'" key="confirmation" class="confirmation-message" :class="control.status">
				<icon class="confirmation-icon" :icon="control.status == 'success' ? 'check-circle' : 'times-circle'" variant="solid"/>
				<div v-html="control.confirmation_message"></div>
				<el-button class="btn-back" type="primary" @click="back" v-if="control.status == 'failure'"><icon icon="arrow-left"/> {{$msg('actions.back')}}</el-button>
			</div>
		</transition-group>
	</div>
</template>

<script>
import Formulario from '@/components/Formulario'
import Handlebars from 'handlebars'
import i18n from '@/mixins/i18n'
import Icon from '@/components/Icon'

export default {
	props: ['id', 'url-api'],
	components: {Formulario, Icon},
	mixins: [i18n],
	data(){
		//this.loadi18nMessages('component', 'ccd')
		this.$api.init(this.urlApi)

		return {
			control: {
				definicion: null,
				status: 'unsaved',
				confirmation_message: null
			},
			dict: {
				es: {
					"confirmacion": {
						"ok": "Los datos se han enviado correctamente. ¡Muchas gracias!",
						"ko": "Se ha producido un error inesperado. Por favor, vuelve a intentarlo en unos instantes."
					},
					"actions": {
						"back": "Volver atrás"
					}
				},
				ca: {
					"confirmacion": {
						"ok": "Les dades s'han enviat correctament.<br><br>Moltes gràcies!",
						"ko": "S'ha produït un error inesperat. Si us plau, torna-ho a provar en uns instants."
					},
					"actions": {
						"back": "Tornar enrere"
					}
				}
			}
		}
	},
	mounted(){
		this.init()
	},
	methods: {
		async init(){
			this.control.definicion = await this.$api.ccds.get(this.id).getData()
		},
		back(){
			this.control.status = 'unsaved'
		},
		showConfirmationMessage(data){
			let template_success = this.control.definicion.msg_ok ? this.control.definicion.msg_ok[this.codidioma] : this.$msg('confirmacion.ok')
			let template_failure = this.control.definicion.msg_ko ? this.control.definicion.msg_ko[this.codidioma] : this.$msg('confirmacion.ko')
			let template = this.control.status == 'success' ? template_success : template_failure

			const compiler = Handlebars.compile(template)
			let output = compiler(data)

			this.control.confirmation_message = output
		},
		async submit(data){
			try{
				await this.$api.ccds.get(this.id).newLead(data, this.codidioma)
				this.control.status = 'success'
				//this.$gtm.push({event: "ccd_submit", data: {idccd: this.id}})
			}catch(e){
				this.control.status = 'failure'
				console.log(e)
			}finally{
				this.showConfirmationMessage(data)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.fade-enter-active {
	transition: all 0.5s ease;
}
.fade-leave-active {
	position: absolute;
	transition: all 0.5s ease;
}
.fade-enter, .fade-leave-to{
	opacity: 0;
}

.confirmation-message{
	padding:20px;
	border-radius:10px;
	text-align:center;

	&.success{
		background-color: #f0f9eb;
    	color: #67c23a;
	}

	&.failure{
		background-color: #fef0f0;
    	color: #f56c6c;
	}

	.confirmation-icon{
		display:block;
		font-size:28px;
		margin-bottom:20px;
	}

	.btn-back{
		margin-top:20px;
	}
}
</style>

<style lang="scss">
.ccd{
	a{
		color: var(--color-link-dynamic-label) !important;
	}

	.rgpd{
		background:#fafafa;
		border-radius:5px;
		border:1px solid #eee;
		font-size:11px !important;
		line-height:16px !important;
		padding:16px;
		margin:10px 0 !important;
		color:#aaa;		
	}

	.rgpd p{
		margin: 0 !important;
	}
		
	.rgpd p+p{
		margin-top: 5px !important;
	}
	
	.rgpd b{    
		color:#888;
		font-family: 'SF Pro Text semibold';
	}
}
</style>