export default class Sorteo {
	constructor(id, deps){
		this.deps = deps
		this.axios = this.deps.axios
		this.api = this.deps.api
		this.id = id
	}

	async getData() {
		return await this.api.post('sorteos/get', {idsorteo: this.id})
	}

	async nuevoParticipante(params = {}){
		params.idsorteo = this.id
		return await this.api.post('sorteos/nuevoParticipante', params)
	}

	async checkEmailDisponible(email){
		const params = {
			idsorteo: this.id,
			email: email
		}
		return await this.api.post('sorteos/checkEmailDisponible', params)
	}

	async getCodigosParticipacionConfirmados(){
		return await this.api.post('sorteos/getCodigosParticipacionConfirmados', {idsorteo: this.id})
	}

	async getParticipante(codparticipacion){
		const params = {
			idsorteo: this.id,
			codparticipacion: codparticipacion
		}
		return await this.api.post('sorteos/getParticipante', params)
	}
}