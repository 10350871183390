<template>
	<div class="i-pickup-availability">
		<div class="i-pickup-availability-preview" v-show="data">
			<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.4103 2.37093L8.27031 0.410929C8.07194 0.347399 7.85868 0.347399 7.66031 0.410929L1.52031 2.37093C1.31796 2.43456 1.14108 2.5609 1.01525 2.73167C0.889417 2.90244 0.821149 3.10881 0.820312 3.32093V11.1809C0.820312 11.5909 1.07031 11.9609 1.45031 12.1109L7.59031 14.5709C7.83031 14.6709 8.09031 14.6709 8.34031 14.5709L14.4803 12.1109C14.6645 12.0353 14.8219 11.9063 14.9324 11.7406C15.0428 11.575 15.1013 11.3801 15.1003 11.1809V3.32093C15.1002 3.11005 15.0333 2.90462 14.9094 2.734C14.7855 2.56338 14.6108 2.4363 14.4103 2.37093ZM7.96031 1.36093L14.0103 3.29093L11.3103 4.19093L5.35031 2.20093L7.98031 1.36093H7.96031ZM7.50031 13.4609L1.82031 11.1809V4.30093L7.50031 6.20093V13.4609ZM8.00031 5.30093L1.96031 3.28093L4.54031 2.46093L10.5303 4.46093L8.00031 5.30093ZM14.1003 11.1909L8.50031 13.4309V6.19093L14.1003 4.32093V11.1909Z" fill="black"></path>
			</svg>
			<div class="pickup-availability-info">
				<p class="caption-large"> </p>
				<p class="caption">
					<b>{{$msg('1')}}</b>
					<template v-if="tienda_seleccionada">
						<span v-if="tienda_seleccionada.disponibilidad == 'N'">{{$msg('2', {tienda: tienda_seleccionada.nombre})}}</span>
						<span v-if="tienda_seleccionada.disponibilidad != 'N'">{{$msg('3', {tienda: tienda_seleccionada.nombre})}}</span>
					</template>
				</p>
				<button id="ShowPickupAvailabilityDrawer" class="pickup-availability-button link link--text underlined-link" aria-haspopup="dialog" @click="open">
					{{tienda_seleccionada ? $msg('4') : $msg('5')}}
				</button>
			</div>
		</div>
		<div ref="drawer" class="i-pickup-availability-drawer" :class="{visible: visible}">
			<div ref="drawer-content" class="i-pickup-availability-drawer-content">
				<div class="pickup-availability-header">
					<h2 class="h3 pickup-availability-drawer-title" id="PickupAvailabilityHeading">{{title}}</h2>
					<button class="pickup-availability-drawer-button" type="button" :aria-label="$msg('10')" @click="close">
						<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" class="icon icon-close" fill="none" viewBox="0 0 18 17">
							<path d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z" fill="currentColor"></path>
						</svg>
					</button>
				</div>
				<ul class="i-pickup-availability-list list-unstyled" role="list" data-store-availability-drawer-content="">
					<li class="pickup-availability-list__item" v-for="shop in shops" :key="shop.codalmacen">
						<div style="display:flex;gap:10px;align-items:center">
							<h3 class="h4" style="flex:1">{{shop.nombre}}</h3>
							<button @click="elegir(shop.codalmacen)" class="link link--text link-select-shop-pickup">{{$msg('6')}}</button>
						</div>
						
						<p class="pickup-availability-preview caption-large" style="display:flex;align-items:center;gap:4px" >
							<template v-if="shop.disponibilidad == 'S'">
								<svg class="icon icon-tick" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" style="width:20px">
									<path fill="#108043" stroke="#fff" d="M16.136 6.979h0l.003-.004a1.499 1.499 0 00-2.275-1.954l-5.945 6.777-1.858-1.859A1.499 1.499 0 103.94 12.06l2.999 3s0 0 0 0c.289.29.675.44 1.1.439h.019a1.5 1.5 0 001.08-.522l6.998-7.998z"></path>
								</svg>
								<span>{{$msg('7')}}</span>
							</template>
							<template v-if="shop.disponibilidad == 'P'">
								<svg class="icon icon-tick" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" style="width:20px">
									<path fill="#108043" stroke="#fff" d="M16.136 6.979h0l.003-.004a1.499 1.499 0 00-2.275-1.954l-5.945 6.777-1.858-1.859A1.499 1.499 0 103.94 12.06l2.999 3s0 0 0 0c.289.29.675.44 1.1.439h.019a1.5 1.5 0 001.08-.522l6.998-7.998z"></path>
								</svg>
								<span>{{$msg('8')}}</span>
							</template>
							<template v-if="shop.disponibilidad == 'N'">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" focusable="false" role="presentation" class="icon icon-unavailable" viewBox="0 0 20 20" style="width:20px">
									<path fill="#DE3618" stroke="#fff" d="M13.94 3.94L10 7.878l-3.94-3.94A1.499 1.499 0 103.94 6.06L7.88 10l-3.94 3.94a1.499 1.499 0 102.12 2.12L10 12.12l3.94 3.94a1.497 1.497 0 002.12 0 1.499 1.499 0 000-2.12L12.122 10l3.94-3.94a1.499 1.499 0 10-2.121-2.12z"/>
								</svg>
								<span>{{$msg('9')}}</span>
							</template>
						</p>
						<address class="pickup-availability-address">
							<p>{{shop.direccion}}<br>{{shop.codpostal}} {{shop.poblacion}}, {{shop.provincia}}</p>
							<p>{{shop.telefono}}</p>
						</address>						
					</li>
				</ul>
			</div>
			<div class="i-pickup-availability-drawer-overlay" @click="close"></div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import i18n from '@/mixins/i18n'

Vue.use(VueCookie)

export default {
	props: ['skus', 'url-api'],
	mixins: [i18n],
	data(){
		this.$api.init(this.urlApi)

		return {
			visible: false,
			sku: null,
			title: null,
			data: null,
			codalmacen: null,
			dict: {
				es: {
					"1": "DISPONIBILIDAD EN TIENDA: ",
					"2": "No hay disponibilidad en {tienda}",
					"3": "Disponible en {tienda} (Normalmente listo en 24 horas)",
					"4": "Ver disponibilidad en otras tiendas",
					"5": "Elige una tienda",
					"6": "Elegir",
					"7": "Disponible",
					"8": "Disponible (quedan pocas unidades)",
					"9": "No disponible",
					"10": "Cerrar"
				},
				ca: {
					"1": "DISPONIBILITAT A BOTIGA: ",
					"2": "No hi ha disponibilitat a {tienda}",
					"3": "Disponible a {tienda} (Normalment preparat en 24 hores)",
					"4": "Veure disponibilitat a altres botigues",
					"5": "Tria una botiga",
					"6": "Triar",
					"7": "Disponible",
					"8": "Disponible (queden poques unitats)",
					"9": "No disponible",
					"10": "Tancar"
				}
			}
		}
	},
	mounted(){
		this.init()
	},
	computed: {
		shops(){
			if (!this.data) return []
			
			let shops = JSON.parse(JSON.stringify(this.data.t))

			let aConStock = []
			let aSinStock = []

			shops.forEach(s => {
				s.disponibilidad = this.getDisponibilidad(s.codalmacen)
				if(s.disponibilidad == 'N'){
					aSinStock.push(s)
				}else{
					aConStock.push(s)
				}
			})

			aConStock.sort(this.sortShops)
			aSinStock.sort(this.sortShops)

			return aConStock.concat(aSinStock)
		},
		tienda_seleccionada(){
			if (!this.codalmacen) return null
			
			let shop = this.shops.find(s => s.codalmacen == this.codalmacen)
			if (!shop) return null

			return {nombre: shop.nombre, disponibilidad: shop.disponibilidad}
		}
	},
	methods: {
		sortShops(a,b){
			if (a.nombre < b.nombre) return -1
			if (a.nombre > b.nombre) return 1
			return 0
		},
		handleEscape(e){
			if (e.key === 'Escape') this.close()
		},
		async init(){
			this.initListeners()
			this.initDrawer()
			await this.fetch()
		},
		initListeners(){
			document.addEventListener("product-updateSelection", this.onUpdateProduct.bind(this))
			document.addEventListener("pickup-updateShop", this.onUpdateShop.bind(this))
		},
		initDrawer(){
			let drawer = this.$refs.drawer
			let parent = drawer.parentNode

			parent.removeChild(drawer)
			document.body.appendChild(drawer)
		},
		async fetch(){
			await this.fetchDisponibilidad()
			await this.initAlmacen()
		},
		onUpdateProduct(e){
			this.sku = e.detail.sku
			this.title = e.detail.title
		},
		onUpdateShop(e){
			this.codalmacen = e.detail.codalmacen
		},
		async fetchDisponibilidad(){
			try{
				this.data = await this.$api.productos.getDisponibilidad(this.skus.toString().split("~"))				
			}catch(e){
				console.log(e)
			}
		},
		initAlmacen(){
			let codalmacen = this.$cookie.get("i_pickup_shop")
			if(codalmacen){
				let tienda = this.data.t.find(t => t.codalmacen == codalmacen)
				if (tienda) this.codalmacen = codalmacen
			}
		},
		open(){
			this.$refs["drawer-content"].scrollTop = 0
			this.visible = true			
			document.addEventListener('keydown', this.handleEscape.bind(this))
		},
		close(){
			this.visible = false
			document.removeEventListener('keydown', this.handleEscape.bind(this))
		},
		getDisponibilidad(codalmacen){
			let producto = this.data.p.find(p => p.sku == this.sku)
			if (!producto) return "N"
			
			return producto.stocks[codalmacen] || "N"
		},
		elegir(codalmacen){			
			this.codalmacen = codalmacen
			this.$cookie.set("i_pickup_shop", codalmacen, { expires: '1M' })

			document.dispatchEvent(new CustomEvent("pickup-updateShop", {detail: {codalmacen: codalmacen}}))

			this.close()
		}
	}
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.i-pickup-availability-drawer{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	pointer-events:none;
	opacity:0;
	transition:0.3s all ease;
	z-index:1000;

	.i-pickup-availability-drawer-content{
		padding:20px;
		z-index:1001;
		position:fixed;
		top:0;
		right:0;
		height: 100%;
		background-color:white;
		width:100%;
		border-left:1px solid rgba(18,18,18,0.1);
		transition:0.3s all ease;
		transform: translate3d(100%,0,0);
		opacity:0;
		overflow:auto;
	}

	&.visible{
		opacity:1;
		pointer-events:initial;

		.i-pickup-availability-drawer-content{
			transition:0.3s all ease;
			transform: translate3d(0,0,0);
			opacity:1;
		}
	}

	.i-pickup-availability-drawer-overlay{
		z-index:1000;
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:block;
	}
}

@media screen and (min-width: 750px){
	.i-pickup-availability-drawer .i-pickup-availability-drawer-content{
		//transform: translate(100%);
		width: 375px;
	}
}

.i-pickup-availability-preview{
	align-items: flex-start;
    display: flex;
    gap: 4px;
}

.pickup-availability-info{
	flex: 1 !important;
	padding-left:4px !important;
}

.i-pickup-availability-drawer{
	.link{
		color: var(--color-tertiary-dynamic-label) !important;
		text-decoration: none !important;

		&:hover{
			color: var(--color-tertiary-dynamic-hover) !important;
    		text-decoration-line: underline !important;
		}
	}
}
</style>