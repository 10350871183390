export default class Productos {
	constructor(deps){
		this.deps = deps
		this.api = this.deps.api
	}

	async setAvisoDisponibilidad(email, sku){
		return await this.api.post('productos/setAvisoDisponibilidad', {email, sku})
	}

	async getDisponibilidad(skus){
		return await this.api.post('productos/disponibilidad', {skus})
	}
}