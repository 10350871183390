<template>
	<i class="icon" :class="[type, faIcon, className]" @click="onClick"></i>
</template>

<script>
export default {
	props: ['icon', 'variant', 'class-name'],
	computed: {
		type(){
			switch(this.variant){
				case "light":	return "fal"
				case "regular": return "far"
				case "solid":	return "fas"
				case "brand":	return "fab"
				default:		return "fas"
			}
		},
		faIcon(){
			return "fa-" + this.icon
		}
	},
	methods: {
		onClick(event){
			this.$emit("click", event)
		}
	}
}
</script>

<style>
@import '@/assets/css/fontawesome/css/all.min.css';
</style>