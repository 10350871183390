export default class Reservas {
	constructor(deps){
		this.deps = deps
		this.api = this.deps.api
	}

	async nueva(params){
		return await this.api.post('reservas/nueva', params)
	}

	async listAll(){
		return await this.api.post('reservas/getReservas')
	}

	async pagar(codigo, lineas){
		return await this.api.post('reservas/pagar', {codigo, lineas})
	}

	async cancelar(codigo){
		return await this.api.post('reservas/cancelar', {codigo})
	}
}