<template>
	<div class="form-kd" v-if="control.initialized">
		<el-form ref="formKD" :model="data" :rules="validation_rules" :show-message="true" @submit.native.prevent :validate-on-rule-change="false" label-position="top" size="small">
			<template v-if="modo == 'alta'">
				<div style="font-weight:bold;font-size:24px">Solicita tu solución de Kit Digital</div>
			</template>

			<template v-if="modo == 'edicion'">
				<template v-if="control.current_step == 0">
					<div style="font-weight:bold;font-size:24px">Continuemos con tu solicitud de Kit Digital</div>
					<div style="font-size:18px;margin-top:20px;margin-bottom:20px;line-height:24px">Por razones de seguridad, indícanos primero el documento de identidad asociado a tu solicitud.</div>
				</template>
				<template v-if="control.current_step > 0 || control.current_step == 'confirmacion'">
					<div style="font-weight:bold;font-size:24px">Revisa tu solicitud de Kit Digital</div>
				</template>
			</template>

			<template v-if="modo == 'edicion' && control.current_step > 0 && control.current_step != 'confirmacion'">
				<template v-if="modo == 'edicion'">
					<div style="margin:20px 0;font-size:18px;">Nos faltan algunos datos para poder continuar con la tramitación de tu solicitud. Por favor, valida que tus datos sean correctos y rellena los campos que pudieran faltar.</div>
				</template>
			</template>

			<div style="margin-top:6px;font-size:14px;color:#6a6a6a;margin-bottom:40px" v-if="control.current_step > 0 && control.current_step != 'confirmacion'">Paso {{control.current_step}} de {{num_steps}}</div>

			<transition :name="'slide-' + control.direction" mode="out-in">
				<div v-if="control.current_step == 0" :key="1">
					<div style="text-align:left">
						<el-form-item prop="docident_beneficiario" label="Documento de identidad">
							<el-input class="fw" v-model="data.docident_beneficiario" placeholder="Indica aquí tu documento de identidad" size="large" @change="onChangeDocIdentBeneficiario"/>
						</el-form-item>
						<el-button type="primary" style="margin-top:20px" @click="checkCodeDocident">Siguiente <icon icon="chevron-right"/></el-button>
					</div>
				</div>

				<div v-if="control.current_step == 1" :key="2">
					<div style="font-weight:bold;font-size:16px">Datos del solicitante</div>

					<el-form-item prop="idtipo_solicitante" label="Eres...">
						<el-select class="fw" v-model="data.idtipo_solicitante" placeholder="Selecciona..." filterable default-first-option @change="onChangeTipoSolicitante" :disabled="modo == 'edicion'">
							<el-option :value="2" label="Autónomo/a"/>
							<el-option :value="3" label="Empresa"/>
							<el-option :value="1" label="Particular"/>
						</el-select>
					</el-form-item>

					<template v-if="data.idtipo_solicitante == 3">
						<div class="cols-2">
							<el-form-item prop="docident_beneficiario" label="CIF">
								<el-input class="fw" v-model="data.docident_beneficiario" placeholder="" @change="onChangeDocIdentBeneficiario" :disabled="modo == 'edicion'"/>
							</el-form-item>

							<el-form-item prop="nombre_empresa" label="Razón social">
								<el-input class="fw" v-model="data.nombre_empresa" placeholder=""/>
							</el-form-item>
						</div>
					</template>

					<template v-if="data.idtipo_solicitante == 2">
						<el-form-item prop="docident_beneficiario" label="DNI / NIE">
							<el-input class="fw" v-model="data.docident_beneficiario" placeholder="" @change="onChangeDocIdentBeneficiario" :disabled="modo == 'edicion'"/>
						</el-form-item>

						<div class="cols-2">
							<el-form-item prop="nombre_beneficiario" label="Nombre">
								<el-input class="fw" v-model="data.nombre_beneficiario" placeholder=""/>
							</el-form-item>

							<el-form-item prop="apellidos_beneficiario" label="Apellidos">
								<el-input class="fw" v-model="data.apellidos_beneficiario" placeholder=""/>
							</el-form-item>
						</div>
					</template>


					<div class="cols-2">
						<el-form-item prop="email" label="Email">
							<el-input class="fw" v-model="data.email" placeholder=""/>
						</el-form-item>

						<el-form-item prop="tlf" label="Teléfono">
							<el-input class="fw" v-model="data.tlf" placeholder=""/>
						</el-form-item>
					</div>

					<el-form-item prop="codidioma" label="Idioma de comunicación">
						<el-select class="fw" v-model="data.codidioma" placeholder="Selecciona..." filterable default-first-option>
							<el-option value="es" label="Castellano"/>
							<el-option value="ca" label="Català"/>
						</el-select>
					</el-form-item>

					<template v-if="modo == 'alta'">
						<el-form-item prop="codestado_bono" label="Estado de tu bono">
							<el-select class="fw" v-model="data.codestado_bono" placeholder="Selecciona..." filterable default-first-option>
								<el-option value="NO_SOLICITADO" label="No me he registrado en Red.es para solicitar el Kit Digital"/>
								<el-option value="CONCEDIDO_NO_SOLICITADO" label="Tengo el bono concedido, pero no he contratado ninguna solución del Kit Digital"/>
								<el-option value="CONCEDIDO_PUESTO_TRABAJO_SEGURO" label="Tengo el bono concedido, ya he contratado una solución previa y ahora quiero tramitar el puesto de trabajo seguro"/>
							</el-select>
						</el-form-item>

						<el-form-item prop="codsolucion" label="¿En qué estás interesado/a?">
							<el-select class="fw" v-model="data.codsolucion" placeholder="Selecciona..." filterable default-first-option>
								<el-option value="PUESTO_TRABAJO_SEGURO" label="Puesto de trabajo seguro"/>
								<el-option value="OTRAS_SOLUCIONES" label="Quiero información de otras soluciones del Kit Digital"/>
							</el-select>
						</el-form-item>
					</template>

					<template v-if="data.codsolucion == 'PUESTO_TRABAJO_SEGURO'">
						<div style="font-weight:bold;margin-top:40px;font-size:16px">Puesto de trabajo seguro</div>
						<el-form-item prop="coddispositivo" label="¿En qué dispositivo estás interesado/a?">
							<div class="list-dispositivos">
								<div class="list-dispositivo-item" v-for="dispositivo in aux.dispositivos" :key="dispositivo.code" @click="selectDispositivo(dispositivo.code)" :class="{'selected': data.coddispositivo == dispositivo.code}">
									<div v-html="dispositivo.content"></div>
								</div>
							</div>
						</el-form-item>
					</template>

					<template v-if="data.codsolucion == 'OTRAS_SOLUCIONES'">
						<div style="font-weight:bold;margin-top:40px;font-size:16px">Observaciones</div>
						<el-form-item prop="observaciones_cliente" label="Indica a continuación cualquier observación que quieras hacernos">
							<el-input class="fw" type="textarea" v-model="data.observaciones_cliente" :rows="4"/>
						</el-form-item>
					</template>

					<el-form-item prop="bprivacidad" style="margin-top:20px" v-if="control.current_step == num_steps">
						<el-checkbox v-model="data.bprivacidad">He leído y acepto la <a href="/politica-privacidad" target="_blank">política de privacidad</a></el-checkbox>
					</el-form-item>
				</div>
				<div v-else-if="control.current_step == 2" :key="3">
					<div style="font-weight:bold;font-size:16px">Dirección</div>

					<el-form-item prop="direccion" label="Dirección fiscal">
						<el-input class="fw" v-model="data.direccion" placeholder=""/>
					</el-form-item>

					<div class="cols-2">
						<el-form-item prop="codpostal" label="Código postal">
							<el-input class="fw" v-model="data.codpostal" placeholder="" @change="onChangeCodPostal"/>
						</el-form-item>

						<el-form-item prop="poblacion" label="Población">
							<el-autocomplete ref="poblacion" class="fw" v-model="data.poblacion" placeholder="" :fetch-suggestions="queryPoblaciones" :trigger-on-focus="true" @select="onSelectPoblacion"/>
						</el-form-item>
					</div>

					<el-form-item prop="provincia" label="Provincia">
						<el-select class="fw" v-model="data.provincia" placeholder="Selecciona..." default-first-option filterable>
							<el-option v-for="provincia in aux.provincias" :key="provincia" :value="provincia" :label="provincia"/>
						</el-select>
					</el-form-item>

					<template v-if="data.idtipo_solicitante == 3">
						<div style="font-weight:bold;margin-top:40px;font-size:16px">Representante de la empresa</div>

						<el-form-item prop="docident_representante" label="DNI / NIE del representante">
							<el-input class="fw" v-model="data.docident_representante" placeholder="" @change="onChangeDocIdentRepresentante"/>
						</el-form-item>

						<div class="cols-2">
							<el-form-item prop="nombre_representante" label="Nombre del representante">
								<el-input class="fw" v-model="data.nombre_representante" placeholder=""/>
							</el-form-item>

							<el-form-item prop="apellidos_representante" label="Apellidos del representante">
								<el-input class="fw" v-model="data.apellidos_representante" placeholder=""/>
							</el-form-item>
						</div>
					</template>

					<div style="font-weight:bold;margin-top:40px;font-size:16px">Observaciones</div>
					<el-form-item prop="observaciones_cliente" label="Indica a continuación cualquier observación que quieras hacernos">
						<el-input class="fw" type="textarea" v-model="data.observaciones_cliente" :rows="4"/>
					</el-form-item>

					<el-form-item prop="bprivacidad" style="margin-top:20px" v-if="control.current_step == num_steps">
						<el-checkbox v-model="data.bprivacidad">He leído y acepto la <a href="/politica-privacidad" target="_blank">política de privacidad</a></el-checkbox>
					</el-form-item>
				</div>

				<div v-if="control.current_step == 'confirmacion'" :key="4">
					<div style="margin:20px 0;font-size:18px">
						<p>Te confirmamos que hemos registrado correctamente tu solicitud. Nos pondremos en contacto contigo para continuar con los trámites de tu Kit Digital a la mayor brevedad posible.</p>
						<p>Muchas gracias por tu confianza.</p>
					</div>
				</div>
			</transition>

			<div style="display:flex;margin-top:20px" v-if="control.current_step > 0">
				<div style="width:120px">
					<el-button type="default" class="fw" @click="prevStep" v-if="control.current_step > 1"><icon icon="chevron-left"/> Anterior</el-button>
				</div>
				<div style="margin-left:auto;width:120px">
					<el-button type="primary" class="fw" @click="nextStep" v-if="control.current_step < num_steps">Siguiente <icon icon="chevron-right"/></el-button>
					<el-button type="primary" class="fw" @click="send" v-if="control.current_step == num_steps" :loading="control.bsending"><icon icon="paper-plane" v-if="!control.bsending"/> Enviar</el-button>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
import { validateSpanishId, spainIdType } from 'spain-id'
import Icon from '@/components/Icon'
import i18n from '@/mixins/i18n'

export default {
	props: {
		"url-api": {type: String, default: null},
		"modo": {type: String, default: 'alta'}
	},
	components: {Icon},
	mixins: [i18n],
	data(){
		this.$api.init(this.urlApi)

		return {
			data: {
				codigo: null,
				idtipo_solicitante: null,
				docident_beneficiario: null,
				nombre_empresa: null,
				docident_representante: null,
				nombre_representante: null,
				apellidos_representante: null,
				nombre_beneficiario: null,
				apellidos_beneficiario: null,
				direccion: null,
				codpostal: null,
				poblacion: null,
				provincia: null,
				email: null,
				tlf: null,
				observaciones_cliente: null,
				dispositivo: null,
				bprivacidad: null,
				codsolucion: "PUESTO_TRABAJO_SEGURO",
				codestado_bono: null,
				coddispositivo: null,
				codidioma: null
			},
			aux: {
				dispositivos: [],
				provincias: [
					'A CORUÑA',
					'ALAVA',
					'ALBACETE',
					'ALICANTE',
					'ALMERIA',
					'ASTURIAS',
					'AVILA',
					'BADAJOZ',
					'BALEARES',
					'BARCELONA',
					'BURGOS',
					'CACERES',
					'CADIZ',
					'CANTABRIA',
					'CASTELLON',
					'CEUTA',
					'CIUDAD REAL',
					'CORDOBA',
					'CUENCA',
					'GIRONA',
					'GRANADA',
					'GUADALAJARA',
					'GUIPUZCOA',
					'HUELVA',
					'HUESCA',
					'JAEN',
					'LA RIOJA',
					'LAS PALMAS',
					'LEON',
					'LLEIDA',
					'LUGO',
					'MADRID',
					'MALAGA',
					'MELILLA',
					'MURCIA',
					'NAVARRA',
					'OURENSE',
					'PALENCIA',
					'PONTEVEDRA',
					'SALAMANCA',
					'SANTA CRUZ DE TENERIFE',
					'SEGOVIA',
					'SEVILLA',
					'SORIA',
					'TARRAGONA',
					'TERUEL',
					'TOLEDO',
					'VALENCIA',
					'VALLADOLID',
					'VIZCAYA',
					'ZAMORA',
					'ZARAGOZA'
				],
				poblaciones: []
			},
			control: {
				docident: null,
				current_step: this.modo == 'alta' ? 1 : 0,
				direction: 'next',
				initialized: false,
				bsending: false
			},
			dict: {
				es: {
					"validacion": {
						"1": "Campo obligatorio",
						"2": "La dirección de correo no tiene un formato válido",
						"3": "Debe informarse un DNI/NIE válido",
						"4": "Debe informarse un CIF válido",
						"5": "Debe informarse un DNI/NIE/CIF válido"
					}
				},
				ca: {
					"validacion": {
						"1": "Camp obligatori",
						"2": "L'adreça de correu no té un format vàlid",
						"3": "Ha d'informar-se un DNI/NIE vàlid",
						"4": "Ja existeix una participació al sorteig amb aquest correu",
						"5": "La data no té un format vàlid (DD/MM/YYYY)",
						"6": "La data introduïda no és vàlida",
						"7": "Ha d'informar-se un CIF vàlid",
					}
				}
			}
		}
	},
	mounted(){
		this.init()
	},
	computed: {
		validation_rules(){
			let rules = {
				observaciones_cliente: {required: this.data.codsolucion != 'PUESTO_TRABAJO_SEGURO', message: this.$msg('validacion.1'), trigger: 'blur'},
				codidioma: {required: true, message: this.$msg('validacion.1'), trigger: 'blur'},
				bprivacidad: {required: this.control.current_step == this.num_steps, type: 'boolean', message: this.$msg('validacion.1'), trigger: 'blur'}
			}

			if(this.control.current_step == 0){
				rules['docident_beneficiario'] = {required: true, validator: this.validate_docidentBeneficiarioStep0, trigger: 'blur'}
			}

			if(this.control.current_step == 1){
				rules['idtipo_solicitante'] = {required: true, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['docident_beneficiario'] = {required: true, validator: this.validate_docidentBeneficiario, trigger: 'blur'}
				rules['nombre_empresa'] = {required: this.data.idtipo_solicitante == 3, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['nombre_beneficiario'] = {required: this.data.idtipo_solicitante == 2, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['apellidos_beneficiario'] = {required: this.data.idtipo_solicitante == 2, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['email'] = {required: true, type: "email", message: this.$msg('validacion.2'), trigger: 'blur'}
				rules['tlf'] = {required: true, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['codestado_bono'] = {required: true, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['codsolucion'] = {required: true, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['coddispositivo'] = {required: this.data.codsolucion == 'PUESTO_TRABAJO_SEGURO', message: this.$msg('validacion.1'), trigger: 'blur'}
			}

			if(this.control.current_step == 2){
				rules['direccion'] = {required: true, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['codpostal'] = {required: true, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['poblacion'] = {required: true, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['provincia'] = {required: true, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['docident_representante'] = {required: this.data.idtipo_solicitante == 3, validator: this.validate_docidentRepresentante, trigger: 'blur'}
				rules['nombre_representante'] = {required: this.data.idtipo_solicitante == 3, message: this.$msg('validacion.1'), trigger: 'blur'}
				rules['apellidos_representante'] = {required: this.data.idtipo_solicitante == 3, message: this.$msg('validacion.1'), trigger: 'blur'}
			}

			return rules
		},
		num_steps(){
			return this.data.codsolucion == 'PUESTO_TRABAJO_SEGURO' ? 2 : 1
		}
	},
	methods: {
		onSelectPoblacion(item){
			this.data.poblacion = item.poblacion
			this.data.provincia = item.provincia
		},
		async onChangeCodPostal(){
			try{
				let data = await this.$api.generico.getInfoCodpostal(this.data.codpostal)

				this.data.poblacion = null
				this.data.provincia = null
				this.aux.poblaciones = []

				if(data.length == 1){
					this.data.poblacion = data[0].poblacion
					this.data.provincia = data[0].provincia				
				}else{
					let provincias = new Set()
					let poblaciones = new Set()

					data.forEach(d =>  {
						provincias.add(d.provincia)
						poblaciones.add(d.poblacion + "~" + d.provincia)
					})

					if(provincias.size == 1){
						this.data.provincia = [...provincias][0]
					}

					if(poblaciones.size == 1){					
						let [poblacion, provincia] = [...poblaciones][0].split("~")
						this.data.poblacion = poblacion
					}else{
						this.aux.poblaciones = [...poblaciones]
					}
				}			

				this.$nextTick(() => this.$refs.poblacion.focus())
			}catch(e){}			
		},
		normalizeLabel(label){
			return label ? label.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ''
		},
		async queryPoblaciones(pattern, cb) {
			let poblaciones = this.aux.poblaciones.map(p => {
				let [poblacion, provincia] = p.split("~")
				return {value: poblacion, poblacion: poblacion, provincia: provincia}
			})
			
			if (pattern) {
				poblaciones = poblaciones.filter(p => {
					let value = this.normalizeLabel(p.value)
					return value.indexOf(pattern.toUpperCase()) > -1
				})
			}

			cb(poblaciones)
		},		
		_cleanDocident(docident){
			return docident.toUpperCase().replace(/-/gi, '').replace(/\s/gi, "")
		},
		onChangeDocIdentBeneficiario(val){
			this.data.docident_beneficiario = this._cleanDocident(val)
		},
		onChangeDocIdentRepresentante(val){
			this.data.docident_representante = this._cleanDocident(val)
		},
		async checkCodeDocident(){
			try{
				let bValid = await this.validate()

				if(bValid) {
					let data = await this.$api.kd_solicitudes.get(this.data.codigo).getDataWithDocident(this.data.docident_beneficiario)
					this.data = data
					this.nextStep()
				}
			}catch(e){
				switch(e.code){
					case "NOT_FOUND":
						this.$alert("No existe ninguna solicitud activa asociada a este documento de identidad.", "Atención", {confirmButtonText: "Aceptar"})
						break
					case "DATOS_NO_SOLICITADOS":
						this.$alert("Actualmente no se requieren datos adicionales para esta solicitud.", "Atención", {confirmButtonText: "Aceptar"})
						break
					default:
						this.$alert("Se ha producido un error inesperado. Por favor, vuelve a intentarlo en unos instantes.", "Atención", {confirmButtonText: "Aceptar"})
						break
				}
			}
		},
		async init(){
			this.getDispositivos()
			if (this.modo == 'edicion') this.setValuesFromQS()
			this.control.initialized = true
		},
		getInfoDocIdent(docident){
			docident = this._cleanDocident(docident)

			let result = {
				docident: docident,
				bvalido: false,
				idtipo_nif: null,
				idtipo_sociedad: null
			}

			if(validateSpanishId(docident)){
				result.bvalido = true

				switch(spainIdType(docident)){
					case "dni":
						result.idtipo_nif = 1
						break
					case "nie":
						result.idtipo_nif = 2
						break
					case "cif":
						result.idtipo_nif = 3

						switch(docident[0]){ //comprobamos primera letra
							case "A": result.idtipo_sociedad = 1; break; //sociedad anónima
							case "B": result.idtipo_sociedad = 2; break; //sociedad limitada
							case "C": result.idtipo_sociedad = 3; break; //sociedad colectiva
							case "D": result.idtipo_sociedad = 4; break; //sociedad comanditaria
							case "E": result.idtipo_sociedad = 5; break; //comunidad de bienes
							case "F": result.idtipo_sociedad = 6; break; //sociedad cooperativa
							case "G": result.idtipo_sociedad = 7; break; //asociacion
							case "H": result.idtipo_sociedad = 8; break; //comunidad de propietarios
							case "J": result.idtipo_sociedad = 9; break; //sociedad civil
							case "N": result.idtipo_sociedad = 10; break; //entidad extranjera
							case "P": result.idtipo_sociedad = 11; break; //corporacion local
							case "Q": result.idtipo_sociedad = 12; break; //organismo publico
							case "R": result.idtipo_sociedad = 13; break; //congregacion o institucion religiosa
							case "S": result.idtipo_sociedad = 14; break; //administracion general del estado
							case "U": result.idtipo_sociedad = 15; break; //union temporal de empresas
							case "V": result.idtipo_sociedad = 16; break; //otros tipos
							case "W": result.idtipo_sociedad = 17; break; //establecimiento permanente de entidades no residentes
						}
						break
				}
			}

			return result
		},
		async getDispositivos(){
			let dispositivos = await this.$api.kd_solicitudes.getDispositivos({bContenidoHTML: true})
			this.aux.dispositivos = dispositivos.map(d => ({code: d.valor, content: d.contenido_HTML}))
		},
		async send(){
			if (this.control.bsending) return false

			// Validamos el paso actual
			let bValid = await this.validate()

			if(!bValid){
				this.$alert("Existen campos obligatorios por rellenar. Por favor, revisa tus datos.", "Atención", {confirmButtonText: "Aceptar"})
				return false
			}

			try{
				this.control.bsending = true
				if(this.modo == 'alta') {
					await this.$api.kd_solicitudes.new().set(this.data)
				}else{
					await this.$api.kd_solicitudes.get(this.data.codigo).completarDatos(this.data)
				}

				this.showConfirmacion()
			}catch(e){
				this.$alert("Se ha producido un error inesperado. Por favor, vuelve a intentarlo en unos instantes.", "Atención", {confirmButtonText: "Aceptar"})
			}finally{
				this.control.bsending = false
			}
		},		
		showConfirmacion(){
			this.control.current_step = 'confirmacion'
		},
		selectDispositivo(code){
			this.data.coddispositivo = code
		},
		async nextStep(){
			// Validamos el paso actual
			let bValid = await this.validate()

			if(!bValid){
				this.$alert("Existen campos obligatorios por rellenar. Por favor, revisa tus datos.", "Atención", {confirmButtonText: "Aceptar"})
				return false
			}

			this.control.current_step++
			this.control.direction = 'next'
		},
		prevStep(){
			this.control.current_step--
			this.control.direction = 'back'
		},
		onChangeTipoSolicitante(val){
			if(val == 1){
				this.$alert("Lo sentimos, la subvención del Kit Digital está destinada solo a autónomos y empresas.", "Atención", {confirmButtonText: "Aceptar"})
				this.data.idtipo_solicitante = null
			}
		},
		async validate(){
			let form = this.$refs['formKD']

			return new Promise((resolve, reject) =>{
				this.$nextTick(() => {
					form.validate((valid, obj)=> {
						return resolve(valid)
					})
				})
			})
		},
		validate_docidentBeneficiario(rule, value, callback){
			if(!value) callback(new Error(this.$msg('validacion.1')))
			else {
				let res = this.getInfoDocIdent(value)

				if (res.bvalido) {
					if(this.data.idtipo_solicitante == "3") {
						if(res.idtipo_nif == 3) callback()
						else callback(new Error(this.$msg('validacion.7')))
					} else {
						if([1,2].includes(res.idtipo_nif)) callback()
						else callback(new Error(this.$msg('validacion.3')))
					}
				} else {
					callback(new Error(this.$msg('validacion.3')))
				}
			}
		},
		validate_docidentBeneficiarioStep0(rule, value, callback){
			if(!value) callback(new Error(this.$msg('validacion.1')))
			else {
				let res = this.getInfoDocIdent(value)

				if (res.bvalido) return callback()
				else return callback(new Error(this.$msg('validacion.5')))
			}
		},
		validate_docidentRepresentante(rule, value, callback){
			if(!value) callback(new Error(this.$msg('validacion.1')))
			else {
				let res = this.getInfoDocIdent(value)

				if (res.bvalido) {
					if([1,2].includes(res.idtipo_nif)) callback()
					else callback(new Error(this.$msg('validacion.3')))
				} else {
					callback(new Error(this.$msg('validacion.3')))
				}
			}
		},
		validate_boolean(rule, value, callback){
			if (value) callback()
			else callback(new Error())
		},
		async setValuesFromQS(){
			const urlParams = new URLSearchParams(window.location.search)
			this.data.codigo = urlParams.get('kd_c')
		}
	}
}
</script>

<style lang="scss" scoped>
.fw{
	width:100%;
}

.cols-2, .cols-3 {
    display:block;
}

/* tablet breakpoint */
@media (min-width:768px) {
    .cols-2, .cols-3 {
        display: grid;
		grid-gap: 20px;
        grid-auto-rows: 1fr;
    }

	.cols-2{
		grid-template-columns: 1fr 1fr;
	}

	.cols-3{
		grid-template-columns: 1fr 1fr 1fr;
	}

	.list-dispositivos{
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	}
}

.list-dispositivos{
	display:grid;
	grid-gap: 8px;

	.list-dispositivo-item{
		border-radius:8px;
		background:#f5f6f7;
		padding:24px;
		border:1px solid transparent;
		cursor:pointer;
		opacity:1;
		transition:0.2s all ease;

		&:hover:not(.selected){
			border-color:#ccc;
		}

		&.selected{
			box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px, rgb(51, 51, 51) 0px 0px 0px 2px;
		}
	}
}

.form-kd{
	font-family:Arial;
}
</style>

<style lang="scss">
.div-slider{
	position:relative;
}
.slide-next-leave-active, .slide-next-enter-active {
  transition: 0.3s;
}
.slide-next-leave-active{
}
.slide-next-enter {
  transform: translate(50px, 0);
  opacity:0;
}
.slide-next-leave-to {
  transform: translate(-50px, 0);
  opacity:0;
}

.slide-back-leave-active, .slide-back-enter-active {
  transition: 0.3s;
}
.slide-back-leave-active{
}
.slide-back-enter {
  transform: translate(-50px, 0);
  opacity:0;
}
.slide-back-leave-to {
  transform: translate(50px, 0);
  opacity:0;
}

.form-kd{
	p{
		margin-bottom:20px;
	}

	.el-form-item__content{
		line-height: 1.5em;
	}

	.el-form-item__label{
		font-size:14px;
		line-height: 1.5em;
		padding: 10px 0;
	}

	a{
		color: var(--color-link-dynamic-label) !important;
	}	
}
</style>