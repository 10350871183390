export default class KDSolicitud {
	constructor(codigo, deps){
		this.deps = deps
		this.axios = this.deps.axios
		this.api = this.deps.api
		this.codigo = codigo
	}

	async getDataWithDocident(docident) {
		return await this.api.post('kd-solicitudes/get', {codigo: this.codigo, docident: docident})
	}

	async set(data) {
		const params = Object.assign({codigo: this.codigo}, data)
		return await this.api.post('kd-solicitudes/set', params)
	}

	async completarDatos(data) {
		const params = Object.assign({codigo: this.codigo}, data)
		return await this.api.post('kd-solicitudes/completarDatos', params)
	}
}