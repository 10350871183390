<template>	
	<modal ref="modal" :title="control.title" size="400px">
		<div class="aviso-disponibilidad">
			<div v-if="!control.bsent">
				<p style="margin-bottom:20px">{{$msg('1')}}</p>
				<el-form ref="form" :model="data" :rules="validation_rules" :show-message="true" @submit.native.prevent>
					<el-form-item prop="email" style="width:100%">
						<el-input placeholder="Dirección de correo" v-model="data.email"/>
					</el-form-item>
					
					<el-form-item prop="bprivacidad">
						<el-checkbox v-model="data.bprivacidad">
							<span v-html="$msg('2', {url_privacidad: urlPrivacidad})"></span>
						</el-checkbox>
					</el-form-item>

					<button @click="save" class="btn-save" style="width:100%;border-radius:40px;margin-top:20px">
						{{$msg('3')}}
					</button>
				</el-form>
			</div>

			<div class="aviso-disponibilidad-confirmacion" v-if="control.bsent">
				{{$msg('4')}}
			</div>
		</div>
	</modal>
</template>

<script>
import Icon from '@/components/Icon'
import Modal from '@/components/Modal'
import i18n from '@/mixins/i18n'

export default {
	props: ['url-privacidad', 'url-api', 'email'],
	components: {Icon, Modal},
	mixins: [i18n],
	data() {
		this.$api.init(this.urlApi)

		return {
			data: {
				email: this.email,
				bprivacidad: false
			},
			control: {
				sku: null,
				title: null,
				dialog_visible: false,
				bsent: false
			},
			dict: {
				es: {
					"1": "Indícanos tu correo electrónico y te avisaremos cuando recibamos nuevas unidades.",
					"2": "He leído y acepto la <a href='{url_privacidad}' target='_blank'>política de privacidad</a>",
					"3": "Enviar",
					"4": "Te confirmamos que hemos registrado tu solicitud y te avisaremos cuando recibamos stock.",
					"validaciones": {
						"1": "Campo obligatorio",
						"2": "Dirección de correo incorrecta",
						"3": "Es necesario aceptar la política de privacidad"
					}
				},
				ca: {
					"1": "Indica'ns el teu correu electrònic i t'avisarem quan rebem noves unitats.",
					"2": "He llegit i accepto la <a href='{url_privacidad}' target='_blank'>política de privacitat</a>",
					"3": "Enviar",
					"4": "Et confirmem que hem registrat la teva sol·licitud i t'avisarem quan rebem estoc.",
					"validaciones": {
						"1": "Camp obligatori",
						"2": "Adreça de correu incorrecta",
						"3": "És necessari acceptar la política de privacitat"
					}
				}
			}
		}
	},
	computed: {
		validation_rules(){
			return {
				email: [
					{required: true, message: this.$msg('validaciones.1'), trigger: "submit"},
					{message: this.$msg('validaciones.2'), type:"email", trigger: "submit"}
					//{validator: this.existeAvisoDisponibilidad, trigger: "blur"}
				],												
				bprivacidad: {validator: this.checkPrivacidad, trigger: "submit"}
			}
		}
	},
	mounted(){
		this.init()
	},
	methods: {
		init(){
			document.addEventListener("product-updateSelection", this.onUpdateProduct.bind(this))	
		},
		reset(){
			this.control.bsent = false
			this.control.email = this.email
			this.control.bprivacidad = false
		},
		open(){
			this.reset()			
			this.$refs.modal.open()
		},
		onUpdateProduct(e){
			this.control.sku = e.detail.sku
			this.control.title = e.detail.title
		},
		async validate(){
			let form = this.$refs['form']
			
			return new Promise((resolve, reject) =>{
				this.$nextTick(() => {
					form.validate((valid, obj)=> {
						return resolve(valid)
					})
				})
			})
		},		
		checkPrivacidad(rule, value, callback){
			if(value) callback()
			else callback(new Error(this.$msg('validaciones.3')))
		},
		async save(){
			let bvalid = await this.validate()

			if (bvalid) {
				try{
					await this.$api.productos.setAvisoDisponibilidad(this.data.email, this.control.sku)
					this.control.bsent = true
				}catch(e){
					console.log(e)
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
.aviso-disponibilidad{
	font-family: SF Pro Text, Helvetica, Arial, sans-serif;
	font-size:14px;

	.flex{
		width:100%;
		display:flex;
		justify-content:center;
		align-items:flex-start;
	}

	.aviso-disponibilidad-confirmacion{
		padding:20px;
		border-radius:5px;
		margin-bottom:10px;
		font-size:13px;
		text-align:center;
		color: #3c763d;
		background-color: #dff0d8;
		border-color: #d6e9c6;
	}

	.btn-save{
		background:#0071E3;
		color:white;
		border:none;
		outline:none;
		border-radius:4px;
		/*width:40px;*/
		padding:10px 20px;
		height:40px;
		display:flex;
		align-items:center;
		justify-content:center;
		
		&:hover{
			opacity:1;
			cursor:pointer;
		}
	}

	.el-form-item{
		margin-bottom:0;

		.el-form-item__content{
			line-height:auto;
		}

		.el-form-item__error{
			position:relative;
		}
	}

	a{
		color: var(--color-link-dynamic-label) !important;
	}

	.el-checkbox__input.is-checked+.el-checkbox__label{
		color: inherit !important;
	}
}
</style>