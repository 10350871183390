import { render, staticRenderFns } from "./FormularioSorteo.vue?vue&type=template&id=f6fc428a&scoped=true&"
import script from "./FormularioSorteo.vue?vue&type=script&lang=js&"
export * from "./FormularioSorteo.vue?vue&type=script&lang=js&"
import style0 from "./FormularioSorteo.vue?vue&type=style&index=0&id=f6fc428a&prod&lang=scss&scoped=true&"
import style1 from "./FormularioSorteo.vue?vue&type=style&index=1&id=f6fc428a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6fc428a",
  null
  
)

export default component.exports