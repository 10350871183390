<template>
	<div class="form-wrapper" v-show="control.bvisible">		
		<el-form v-if="control.definicion" ref="form" :model="data" :rules="validation_rules" :show-message="true" @submit.native.prevent :validate-on-rule-change="false">
			<template v-for="campo in aux.esquema">
				<el-form-item :key="campo.fieldname" :prop="campo.fieldname" :label="campo.type != 'boolean' ? campo.label : ''" v-if="!campo.hidden">
					<component class="fw" :is="campo.ui.component" v-model="data[campo.fieldname]" v-bind="campo.ui.attrs">
						<div v-if="campo.ui.slot" v-html="campo.ui.slot"></div>
						<template v-if="campo.ui.component == 'el-select'">
							<component :is="'el-option'" v-for="opt in campo.ui.attrs.options" :key="opt.value" :value="opt.value" :label="opt.label[codidioma]"/>
						</template>
					</component>
				</el-form-item>
			</template>
					
			<div class="actions" style="margin: 20px 0;text-align:center">
				<pln-button type="primary" size="large" @click="save" style="width:100%;max-width:500px;margin:auto">
					<span v-html="control.definicion.esquema.submit[codidioma]"/>
				</pln-button>
			</div>
		</el-form>
	</div>
</template>

<script>
import PlnButton from '@/components/PlnButton'
import { validateSpanishId } from 'spain-id'
import i18n from '@/mixins/i18n'
import Icon from '@/components/Icon'

export default {
	components: {PlnButton, Icon},
	props: ['id', 'submit', 'url-api', 'ready'],
	mixins: [i18n],
	data() {
		//this.loadi18nMessages('component', 'formulario')
		this.$api.init(this.urlApi)

		return {
			data: {},
			aux: {
				esquema: []
			},
			control: {
				definicion: null,
				bvisible: false
			},
			dict: {
				es: {
					"validacion": {
						"1": "Campo obligatorio",
						"2": "La dirección de correo no tiene un formato válido",
						"3": "El documento de identidad no es válido",
						"4": "La fecha no tiene un formato válido (DD/MM/YYYY)",
						"5": "La fecha introducida no es válida"
					}
				},
				ca: {
					"validacion": {
						"1": "Camp obligatori",
						"2": "L'adreça de correu no té un format vàlid",
						"3": "El document d'identitat no és vàlid",
						"4": "La data no té un format vàlid (DD/MM/YYYY)",
						"5": "La data introduïda no és vàlida"
					}
				}
			}
		}
	},
	mounted(){
		this.init()
	},
	computed: {
		validation_rules(){
			let rules = {}

			this.aux.esquema.forEach(campo => {
				let fieldRules = []
				if(campo.required){
					if(campo.type == 'boolean'){
						fieldRules.push({type: 'boolean', required: true, validator: this.validate_boolean, message: this.$msg('validacion.1'), trigger: 'blur'})
					}else{
						fieldRules.push({required: true, message: this.$msg('validacion.1'), trigger: 'blur'})
					}
				}
				
				if(campo.customValidation){
					campo.customValidation.forEach(cv => {
						fieldRules.push({validator: this['validate_' + cv], trigger: 'blur'})
					})
				}

				switch(campo.type){
					case "string":
						switch(campo.format){
							case "email":
								fieldRules.push({type: "email", message: this.$msg('validacion.2'), trigger: "blur"})
								break
						}
						break
					case "date":
						fieldRules.push({validator: this.validate_date, trigger: "blur"})
						break
					default:break
				}

				if(fieldRules.length > 0){
					rules[campo.fieldname] = fieldRules
				}
			})

			return rules
		}
	},
	methods: {
		async init(){
			this.control.definicion = await this.$api.formularios.getDefinicion(this.id)

			this.initEsquema()
			this.initValues()
			this.setFormReady()
		},
		async setFormReady(){
			if (this.ready) await this.ready()
			this.control.bvisible = true
		},
		setFieldValue(field, value){
			if (this.data.hasOwnProperty(field)) {
				this.data[field] = value
			}
		},
		initEsquema(){
			let esquema = []

			this.control.definicion.esquema.fields.forEach(campo => {
				campo.label = campo.label[this.codidioma]
				esquema.push(campo)
			})

			esquema.forEach(campo => {
				let component
				let attrs = {}
				let slot

				if(campo.placeholder){
					attrs.placeholder = campo.placeholder[this.codidioma]
				}
				// Componente a renderizar
				switch(campo.type){
					case "date":
						component = "el-input"
						attrs.type = 'text'

						break
					case "string":			
						component = 'el-input'

						if (campo.maxlength) attrs.maxlength = campo.maxlength

						switch(campo.format){
							case "email":
								attrs.type = 'email'
								break
							case "phone":
								attrs.type = 'tel'
								break
							case "textarea":
								attrs.type = "textarea"
								attrs.autosize = {minRows: 2}
								break
							default:
								attrs.type = 'text'
								break
						}
						
						break
					case "boolean":
						component = 'el-checkbox'
						slot = campo.label
						break
					case "select":						
						component = 'el-select'
						attrs.options = campo.options
						attrs.multiple = campo.multiple
						break
					default:
						component = 'el-input'
						break
				}

				campo.ui = {component, attrs, slot}
			})

			this.aux.esquema = esquema
		},
		initValues(){
			let data = {}

			this.aux.esquema.forEach(campo => {
				data[campo.fieldname] = campo.type == 'boolean' ? false : null
			})

			this.data = data
		},
		async validate(){
			let form = this.$refs['form']
			
			return new Promise((resolve, reject) =>{
				this.$nextTick(() => {
					form.validate((valid, obj)=> {
						return resolve(valid)
					})
				})
			})
		},		
		validate_boolean(rule, value, callback){
			if (value) callback()
			else callback(new Error())
		},
		validate_docident(rule, value, callback){
			let bvalid = validateSpanishId(value)

			if(bvalid) callback()
			else callback(new Error(this.$msg('validacion.3')))
		},
		validate_date(rule, value, callback){
			if (!value) return callback()
			
			let formatValid = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/.test(value)			
			if (!formatValid) return callback(new Error(this.$msg('validacion.4')))
			
			let [day, month, year] = value.split("/")
			month = month-1

			let date = new Date(year, month, day)
			let dateValid = date.getDate() == day && date.getMonth() == month && date.getFullYear() == year

			if (!dateValid) return callback(new Error(this.$msg('validacion.5')))
			callback()			
		},
		async save(){
			let bValid = await this.validate()

			if(bValid){
				let data = {}

				this.aux.esquema.forEach(campo => {
					let value = this.data[campo.fieldname]

					if(campo.type == 'date'){
						let [day, month, year] = val.split("/")
						value = year + "-" + month + "-" + day
					}

					if(campo.type == 'select'){
						let opt = campo.options.find(opt => opt.value == value)												
						data[campo.fieldname] = value
						if (opt) data["_" + campo.fieldname] = opt.label
					}else{
						data[campo.fieldname] = value
					}
				})
				
				await this.submit(data)				
			}
		}
	}
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.form-wrapper{
	.el-form{
		.el-form-item{
			margin-bottom:10px;

			&.is-required{
				.el-form-item__label, .el-checkbox__label{
					&:after{
						content: '*';
						margin-left:3px;
						color:red;
					}
				}
			}
		}

		.el-form-item__error{
			position:relative;
			top:0;
		}

		.el-checkbox{
			margin-bottom:0;

			.el-checkbox__label > div{
				display:inline;
			}
		}
	}

	.fw{
		width:100%;
	}

	a{
		color: var(--color-link-dynamic-label) !important;
	}	
}
</style>