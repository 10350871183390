import Vue from 'vue'
import VueCustomElement from 'vue-custom-element'
Vue.use(VueCustomElement)

import API from '@/api'
Vue.prototype.$api = new API({})

import 'element-ui/lib/theme-chalk/input.css';
import 'element-ui/lib/theme-chalk/input-number.css';
import 'element-ui/lib/theme-chalk/checkbox.css';
import 'element-ui/lib/theme-chalk/select.css';
import 'element-ui/lib/theme-chalk/option.css';
import 'element-ui/lib/theme-chalk/button.css';
import 'element-ui/lib/theme-chalk/tooltip.css';
import 'element-ui/lib/theme-chalk/form.css';
import 'element-ui/lib/theme-chalk/form-item.css';
import 'element-ui/lib/theme-chalk/row.css';
import 'element-ui/lib/theme-chalk/col.css';
import 'element-ui/lib/theme-chalk/upload.css';
import 'element-ui/lib/theme-chalk/loading.css';
import 'element-ui/lib/theme-chalk/message-box.css';
import 'element-ui/lib/theme-chalk/message.css';

import {
  Input,
  Autocomplete,
  InputNumber,
  Checkbox,
  Select,
  Option,
  Button,
  Tooltip,
  Form,
  FormItem,
  Row,
  Col,
  Upload,
  Loading,
  MessageBox,
  Message,
} from 'element-ui';

Vue.use(Input);
Vue.use(Autocomplete);
Vue.use(InputNumber);
Vue.use(Checkbox);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;

//import ElementUI from 'element-ui'
//import 'element-ui/lib/theme-chalk/index.css'
//import locale from 'element-ui/lib/locale/lang/es'
//Vue.use(ElementUI, {locale})

import './assets/css/main.css'

import Reserva from "./components/Reserva.vue"
Vue.customElement('i-reserva', Reserva)

import Reservas from "./components/Reservas.vue"
Vue.customElement('i-reservas', Reservas)

import PickupAvailability from "./components/PickupAvailability.vue"
Vue.customElement('i-pickup-availability', PickupAvailability)

import Newsletter from "./components/Newsletter.vue"
Vue.customElement('i-newsletter', Newsletter)

import Chat from "./components/Chat.vue"
Vue.customElement('i-chat', Chat)

import ShareContent from "./components/ShareContent.vue"
Vue.customElement('i-share-content', ShareContent)

import NotifyAvailability from "./components/NotifyAvailability.vue"
Vue.customElement('i-notify-availability', NotifyAvailability)

import TDRenew from "./components/TDRenew.vue"
Vue.customElement('i-td-renew', TDRenew)

import CCD from "./components/CCD.vue"
Vue.customElement('i-ccd', CCD)

import Contacto from "./components/Contacto.vue"
Vue.customElement('i-contacto', Contacto)

import FormularioSorteo from "./components/FormularioSorteo.vue"
Vue.customElement('i-formulario-sorteo', FormularioSorteo)

import SimuladoresFinanciacion from "./components/SimuladoresFinanciacion.vue"
Vue.customElement('i-simuladores-financiacion', SimuladoresFinanciacion)

import KD from "./components/KD.vue"
Vue.customElement('i-kd', KD)