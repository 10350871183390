export default class Suscripciones {
	constructor(deps){
		this.deps = deps
		this.api = this.deps.api
	}

	async emailCheck(email){
		console.log("ENTRO")
		let params = {
			email: email
		}
		return await this.api.post('suscripciones/emailcheck', params)
	}

	async getSectores(){
		return await this.api.post('suscripciones/getSectores', {})
	}

	async invitar(params){
		return await this.api.post('suscripciones/invitar', params)
	}

	hola(){
		return "EI"
	}
}