<template>
	<drawer ref="drawer" :paddingInBody="false" :title="control.producto">
		<div class="full reserva">
			<steps ref="steps" page="reserva" :first-step-code="first_step" width="100%" height="100%">
				<!--step code="account">
					<div class="step-content">
						<h2>{{$msg('1')}}</h2>
						<p style="font-size:14px !important;color:#888;margin-bottom:20px !important">{{$msg('2')}}</p>
						<login ref="login" @logged-in="onLoggedIn" :include-sign-up="true" @go-to-sign-up="onUserSignUp" @forgot-password="onForgotPassword" :round="true" :hide-title="true"/>
					</div>		
				</step>
				<step code="sign-up">
					<div class="step-content">
						<div>
							<h2>{{$msg('3')}}</h2>
							<sign-up :round="true" :hide-title="true" :hide-login="true" :redirect="redirect" origen="reserva"/>
						</div>
					</div>
				</step>
				<step code="forgot-password">
					<div class="step-content">
						<div>
							<h2>{{$msg('4')}}</h2>
							<forgot-password ref="forgot" size="default"/>
						</div>
					</div>
				</step-->
				<step code="summary">
					<div class="step-content">
						<div>
							<!--div class="eyebrow">{{$msg('5')}}</div-->
							<!--h2>{{control.producto}}</h2-->

							<p style="font-size:14px !important;margin-bottom:20px !important">{{$msg('6')}}</p>
							<el-form ref="form" :model="data" :rules="validation_rules">
								<!--div class="modificar-datos" v-if="!this.control.bedit_datos_personales">
									{{$msg('7')}}
									<br>
									<el-button type="text" @click="modificarDatosPersonales" size="mini">{{$msg('8')}}</el-button>
								</div-->
								<el-row :gutter="20">
									<el-col :span="12">
										<el-form-item prop="nombre" :label="$msg('9')">
											<el-input v-model="data.nombre" :disabled="usuarioNombre ? true : false" maxlength="100"/>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item prop="apellidos" :label="$msg('10')">
											<el-input v-model="data.apellidos" :disabled="usuarioApellidos ? true : false" maxlength="100"/>
										</el-form-item>
									</el-col>
								</el-row>
								<el-form-item prop="email" :label="$msg('12')">
									<el-input v-model="data.email" :disabled="usuarioEmail ? true : false" @paste.native="cancelPaste" autocomplete="off"/>
								</el-form-item>
								<el-form-item prop="email2" :label="$msg('26')" v-if="usuarioEmail ? false : true">
									<el-input v-model="data.email2" @paste.native="cancelPaste" autocomplete="off"/>
								</el-form-item>
								<el-form-item prop="telefono" :label="$msg('11')">
									<el-input v-model="data.telefono" maxlength="15" :disabled="usuarioTlf ? true : false"/>
								</el-form-item>									
								<el-form-item prop="unidades" :label="$msg('13')">
									<el-input-number class="reserva-units" v-model="data.unidades" :step="1" :min="1"/>
								</el-form-item>

								<el-form-item prop="codmetodoentrega" :label="$msg('14')" v-if="data.unidades > 1">
									<el-select class="fw" popper-class="select-option-multiline" v-model="data.bentrega_total" default-first-option >
										<el-option v-for="modo in modos_entrega" :key="modo.value" :value="modo.value" :label="modo.title">
											<template>
												<div>
													<div>{{modo.title}}</div>
													<div style="margin-top:-12px;font-size:11px;opacity:0.8;">{{modo.description}}</div>
												</div>
											</template>
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item prop="bnewsletter">
									<el-checkbox v-model="data.bnewsletter">{{$msg('25')}}</el-checkbox>
								</el-form-item>
							</el-form>

							<el-button @click="reservar" type="primary" size="large" round class="fw" style="margin-top:20px" :disabled="control.benviando">{{$msg('17')}}</el-button>
						</div>
					</div>
				</step>
				<step code="confirmation">
					<div class="step-content">
						<div class="confirmation-ok">
							<icon icon="check-circle"/>
							<h2 v-html="$msg('18')"></h2>
							<p>{{$msg('19')}}</p>
							<p>{{$msg('20')}}</p>
							<p>{{$msg('21')}}</p>
							<el-button @click="close" type="primary" size="large" round class="fw" style="margin-top:20px">{{$msg('22')}}</el-button>
						</div>
					</div>
				</step>
			</steps>
			<icon class="step-back" icon="chevron-left" variant="light" @click="goBack" :class="{visible:isBackVisible}"/>
		</div>		
	</drawer>
</template>

<script>
import Drawer from '@/components/Drawer'
import Steps from '@/components/Steps'
import Step from '@/components/Step'
import Icon from '@/components/Icon'
import i18n from '@/mixins/i18n'

export default {
	components: {Drawer, Steps, Step, Icon},
	props: {
		"usuario-nombre": {
			type: String,
			default: null
		},
		"usuario-apellidos": {
			type: String,
			default: null
		},
		"usuario-email": {
			type: String,
			default: null
		},
		"usuario-tlf": {
			type: String,
			default: null
		}
	},
	mixins: [i18n],
	data(){
		//this.loadi18nMessages('component', 'reserva')
		this.$api.init(this.urlApi)
		
		return {
			data: {
				nombre: null,
				apellidos: null,
				telefono: null,
				email: null,
				email2: null,
				unidades: 1,
				bentrega_total: 1,
				bnewsletter: false
			},
			aux: {
			},
			control: {
				benviando: false,
				current_step: "summary",
				bedit_datos_personales: false,
				sku: null,
				producto: null
			},
			dict: {
				es: {
					"1": "Conéctate",
					"2": "Para confirmar tu reserva es necesario acceder a tu cuenta de usuario. Tener una cuenta te permitirá, además de gestionar tus reservas, consultar todos tus pedidos, tus seminarios y tus datos personales, entre otros, de la forma más fácil y cómoda.",
					"3": "Regístrate",
					"4": "Recuperar contraseña",
					"5": "Tu reserva",
					"6": "Por favor, facilítanos tus datos para que podamos ponernos en contacto contigo en cuanto tengamos disponibilidad para este producto. Y no te preocupes, no tendrás que abonar nada por la reserva y podrás cancelarla en cualquier momento sin ningún compromiso.",
					"7": "Éstos son los datos de tu cuenta. Por favor, revisa que estén actualizados para que podamos ponernos en contacto contigo.",
					"8": "Actualizar mis datos personales",
					"9": "Nombre",
					"10": "Apellidos",
					"11": "Teléfono",
					"12": "Correo electrónico",
					"13": "Unidades",
					"14": "¿Cómo prefieres que te lo entreguemos?",
					"15": "Selecciona...",
					"16": "Selecciona la tienda de recogida",
					"17": "Reservar",
					"18": "¡Tu reserva<br>se ha realizado con éxito!",
					"19": "Te hemos enviado un correo electrónico a la dirección que nos has indicado confirmándote tu reserva.",
					"20": "La reserva realizada nos da derecho a ponernos en contacto contigo para que puedas formalizar la compra del dispositivo en la fecha en la que éste esté disponible.",
					"21": "Muchas gracias por confiar en nosotros.",
					"22": "Cerrar",
					"23": "Enviar a casa",
					"24": "Recoger en tienda",
					"25": "Deseo que INTECAT me informe de ofertas, eventos y novedades",
					"26": "Repetir correo electrónico",
					"validacion": {
						"requerido": "Campo obligatorio",
						"email_incorrecto": "El formato del correo no es correcto",
						"email_diferente": "Las direcciones de correo no coinciden"
					},
					"modos_entrega": {
						"junto_titulo": "Todos los productos juntos", 
						"junto_descripcion": "Prefieres la entrega de todas las unidades en el mismo momento",
						"separado_titulo": "Entrega parcial permitida", 
						"separado_descripcion": "Prefieres entregas parciales a medida que lleguen unidades",
					}
				},
				ca: {
					"1": "Connecta't",
					"2": "Per a confirmar la teva reserva és necessari accedir al teu compte d'usuari. Tenir un compte et permetrà, a més de gestionar les teves reserves, consultar totes les teves comandes, els teus seminaris i les teves dades personals, entre altres, de la forma més fàcil i còmoda.",
					"3": "Registra't",
					"4": "Recuperar contrasenya",
					"5": "La teva reserva",
					"6": "Si us plau, facilita'ns les teves dades perquè puguem posar-nos en contacte amb tu quan tinguem disponibilitat per a aquest producte. I no et preocupis, no hauràs d'abonar res per la reserva i podràs cancel·lar-la en qualsevol moment sense cap compromís.",
					"7": "Aquestes són les dades del teu compte. Si us plau, revisa que estiguin actualitzades perquè puguem posar-nos en contacte amb tu.",
					"8": "Actualitzar les meves dades personals",
					"9": "Nom",
					"10": "Cognoms",
					"11": "Telèfon",
					"12": "Correu electrònic",
					"13": "Unitats",
					"14": "Com prefereixes que t'ho lliurem?",
					"15": "Selecciona...",
					"16": "Selecciona la botiga de recollida",
					"17": "Reservar",
					"18": "La teva reserva<br>s'ha realitzat amb èxit!",
					"19": "T'hem enviat un correu electrònic a l'adreça que ens has indicat confirmant-te la teva reserva.",
					"20": "La reserva realitzada ens dóna dret a posar-nos en contacte amb tu perquè puguis formalitzar la compra del dispositiu en la data en la qual aquest estigui disponible.",
					"21": "Moltes gràcies per confiar en nosaltres.",
					"22": "Tancar",
					"23": "Enviar a casa",
					"24": "Recollir a botiga",
					"25": "Desitjo que INTECAT m'informi d'ofertes, esdeveniments i novetats",
					"26": "Repetir correu electrònic",
					"validacion": {
						"requerido": "Camp obligatori",
						"email_incorrecto": "El format del correu no és correcte",
						"email_diferente": "Les direccions de correu no coincideixen"
					},
					"modos_entrega": {
						"junto_titulo": "Tots els productes junts", 
						"junto_descripcion": "Prefereixes l'entrega de totes les unitats al mateix moment",
						"separado_titulo": "Entrega parcial permesa", 
						"separado_descripcion": "Prefereixes entregues parcials a mesura que arribin unitats"
					}
				}
			}
		}
	},
	computed: {
		modos_entrega(){
			return [
				{value: 1, title: this.$msg('modos_entrega.junto_titulo'), description: this.$msg('modos_entrega.junto_descripcion')},
				{value: 0, title: this.$msg('modos_entrega.separado_titulo'), description: this.$msg('modos_entrega.separado_descripcion')}
			]
		},
		usuario(){
			return {
				nombre: this.usuarioNombre || null,
				apellidos: this.usuarioApellidos || null,
				telefono: this.usuarioTlf || null,
				email: this.usuarioEmail || null,
				bconectado: false
			}
		},
		first_step(){
			//if (!this.usuario.bconectado) return 'account'
			return 'summary'
		},
		isBackVisible(){
			if(this.control.current_step == 'account' && this.usuario.bconectado) return false
			if(this.control.current_step == this.first_step) return false
			if(this.control.current_step == "confirmation") return false
			return true
		},
		validation_rules(){
			let rules = {
				nombre: {required: true, message: this.$msg('validacion.requerido'), trigger:"submit"},
				apellidos: {required: true, message: this.$msg('validacion.requerido'), trigger:"submit"},
				telefono: {required: true, message: this.$msg('validacion.requerido'), trigger:"submit"},
				email: [
					{required: true, message: this.$msg('validacion.requerido'), trigger:"submit"},
					{required: true, type:"email", message: this.$msg('validacion.email_incorrecto'), trigger:"submit"}
				],
				unidades: {required: true, message: this.$msg('validacion.requerido'), trigger:"submit"},
				bnewsletter: {required: true, validator: this.checkNewsletter, trigger:"submit"}				
			}

			if (!this.usuarioEmail) {
				rules.email2 = [
					{required: true, message: this.$msg('validacion.requerido'), trigger:"submit"},
					{required: true, type:"email", message: this.$msg('validacion.email_incorrecto'), trigger:"submit"},
					{validator: this.checkSameEmailAddress, trigger: "submit"}
				]
			}

			return rules
		}
	},
	mounted(){
		this.init()
	},
	methods: {
		init(){
			document.addEventListener("product-updateSelection", this.onUpdateProduct.bind(this))
		},
		onUpdateProduct(e){
			console.log("pickup.onUpdateProduct", e)
			this.control.sku = e.detail.sku
			this.control.producto = e.detail.title
		},
		cancelPaste(e){
			e.stopPropagation()
			e.preventDefault()
			return false
		},
		clearForm(){
			let usuario = this.usuario

			this.data.nombre = usuario.nombre
			this.data.apellidos = usuario.apellidos
			this.data.telefono = usuario.telefono
			this.data.email = usuario.email
			this.data.email2 = null
			this.data.unidades = 1
			this.data.bentrega_total = 1
			this.data.bnewsletter = false
			this.control.bedit_datos_personales = false
		},
		open(){
			this.clearForm()
			this.$refs.steps.set(this.first_step)
			this.$refs.drawer.open()
		},
		/*async nuevaReserva(params){
			return await this.$api.reservas.nueva(params)			
			//let result = await this.api.post('cliente/nuevaReserva', params)
			//let {data} = await axios.post("https://des-store.intecat.com/shopify/reservas/nueva", params, { withCredentials: true })
			//return true
		},*/
		async reservar(){
			let valid = await this.validate()
			
			if(valid){
				try{
					let params = {
						sku: this.control.sku,
						nombre: this.data.nombre,
						apellidos: this.data.apellidos,
						tlf: this.data.telefono,
						email: this.data.email,
						unidades: this.data.unidades,
						bentrega_total: this.data.bentrega_total
					}

					this.control.benviando = true
					let result = await this.$api.reservas.nueva(params)
					if (result) this.siguiente("confirmation")
				}catch(e){					
					console.log(e)
				}finally{
					this.control.benviando = false
				}
			}
		},
		async validate(){
			let form = this.$refs['form']
			
			return new Promise((resolve, reject) =>{
				this.$nextTick(() => {
					form.validate((valid, obj)=> {
						return resolve(valid)
					})
				})
			})
		},
		close(){
			this.$refs.drawer.close()
		},
		siguiente(code){
			this.$refs.steps.next(code)
			this.control.current_step = code
		},
		anterior(code){
			this.$refs.steps.prev(code)
			this.control.current_step = code
		},
		goBack(){
			switch(this.control.current_step){
				case "reserva":	
					return this.anterior("account")
				case "login":
					return this.anterior("account")
				case "sign-up":	
					return this.anterior("account")
				case "forgot-password":
					return this.anterior("account")
			}
		},
		/*onLoggedIn(){
			let usuario = this.$store.state.usuario

			this.data.nombre = (usuario.bconectado ? usuario.nombre : null)
			this.data.apellidos = (usuario.bconectado ? usuario.apellidos : null)
			this.data.telefono = (usuario.bconectado ? usuario.telefono : null)
			this.data.email = (usuario.bconectado ? usuario.email : null)
			this.control.bedit_datos_personales = false

			this.siguiente("summary")
		},*/
		onUserSignUp(){
			this.siguiente("sign-up")
		},
		onForgotPassword(){
			this.siguiente("forgot-password")
		},
		modificarDatosPersonales(){
			this.control.bedit_datos_personales = true
		},
		checkNewsletter(rule, value, callback){
			if(value) callback()
			else callback(new Error(this.$msg('validacion.requerido')))
		},
		async checkSameEmailAddress(rule,value,callback) {
			if (this.data.email == this.data.email2) callback()
			else callback(new Error(this.$msg('validacion.email_diferente')))
		},
	}
}
</script>

<style lang="scss" scoped>
	.reserva{
		font-family: SF Pro Text, Arial, sans-serif;
		color:#2a2a2a;
		
		div.seminar-heading{
			font-size:15px;
		//	font-family:"Montserrat";
			font-weight:bold;
			color:#aaa;
			margin-top:20px;
			margin-bottom:10px;
		}

		img{
			width:150px;
		}

		h2{
			font-size:24px;
		//	font-family:"Montserrat";
			font-weight:bold;
			margin-bottom:40px;
		}

		.eyebrow+h2{
			margin-top:10px;			
		}

		h3{
			margin-bottom:20px;
		}
		
		p{
			font-weight:100;
			font-size:14px;
			line-height:1.5;
			margin-bottom:20px;
		}

		.label{
			font-size:12px;
		}

		.seminar-day, .seminar-hour, .seminar-length{
			font-weight:600
		}
	}

	.step-content{
		/*height:100%;*/
		//margin-top:40px;
		
		box-sizing:border-box;
		/*overflow:auto;*/
	}

	@media (max-width: 560px) {
		.step-content{
			padding:24px;
		}
	}
	
	@media (min-width: 560px) {
		.step-content{
			padding:24px;
		}
	}

	

	.step-actions{
		position:sticky;
		background:white;
		height:100px;
		bottom:0px;
		padding:30px;
		border-bottom-left-radius:20px;
		border-bottom-right-radius:20px;
		overflow:hidden;
	}

	.step-back{
		cursor:pointer;
		opacity:0;
		font-size:20px;
		font-weight:100;
		position:absolute;
		top:30px;
		left:-30px;
		-webkit-font-smoothing: antialiased;
		transition:0.8s all ease;

		&.visible{
			opacity:0.6;
			left:30px;
		}

		&:hover{
			opacity:1;
		}
	}

	.full{
		width:100%;
		height:100%;
		position:relative;
		top:0;
		left:0;
		overflow:auto;
		border-radius:20px;
	}

	.confirmation-ok{
		h2{
			color:#10A66A;
			
		}
		.icon{
			color:#10A66A;
			font-size:30px;
			margin-bottom:20px;
		}
		
	}

	.modificar-datos{
		font-size:14px;
		text-align:center;
		margin-bottom:20px;
		background:#fafafa;
		padding:10px;
	}

	.eyebrow{
		font-size:14px;		
		font-weight:bold;
	}

	.fw{
		width:100%;
	}
</style>

<style lang="scss">
.reserva-units.el-input-number{
	width:100%;
	.el-input-number__increase, .el-input-number__decrease{
		background:transparent;
		transition:0.2s all ease;

		&:hover{
			background:#fafafa;
		}
	}
}

.select-option-multiline .el-select-dropdown__item{
	height: auto !important;
}

.el-checkbox{
	display:inline-flex !important;
}

.el-checkbox__label{
	word-break: initial;
	margin-top:-2px;
}

.el-checkbox, .el-checkbox__input{
	white-space:normal !important;
}

.el-form-item{
	margin-bottom: 12px !important;
}

.el-select-dropdown{
	font-family: SF Pro Text, Arial, sans-serif;
}

.el-button--primary{
	background-color: #0071E3 !important;
	border-color: #0071E3 !important;

	&:hover{
		background-color: #62bbfa !important;
		border-color: #62bbfa !important;
	}
}

.el-form-item__error{
	margin-top:6px !important;
	position:relative !important;
}

.el-input-number{
	overflow:hidden !important;
}

.el-input-number__decrease, .el-input-number__increase{
	height: calc(100% - 2px) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
</style>