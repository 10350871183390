import Sorteo from './sorteo'

export default class Sorteos {
	constructor(deps){
		this.deps = deps
		this.axios = this.deps.axios
		this.api = this.deps.api
	}

	get(id){
		return new Sorteo(id, this.deps)
	}

	async confirmarParticipacion(codigo){
		return await this.api.post('sorteos/confirmarParticipacion', {codigo: codigo})
	}
	
	async nuevoParticipante(params = {}){
		params.idsorteo = this.id
		return await this.api.post('sorteos/nuevoParticipante', params)
	}

	async checkEmailDisponible(email){
		const params = {
			idsorteo: this.id,
			email: email
		}
		return await this.api.post('sorteos/checkEmailDisponible', params)
	}
}