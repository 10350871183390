import {format, isAfter} from 'date-fns'

Number.prototype.rnd = function(precission){ 
    let val = null

	if(!("" + this).includes("e")) {
		val = this+"e"+precission
	} else {
		let tmp = ("" + this).split("e");
		let sign = ""
		let num = +tmp[0] //obtiene la representación numérica de la variable
		let e = +tmp[1] //obtiene la representación numérica de la variable
		if(e + precission > 0) {
			sign = "+";
		}

		val = num + "e" + sign + (e + precission)
	}

	return Number((Math.sign(val) * Math.round(Math.abs(val))) + "e-" + precission)
}

Number.prototype.format = function(decimals, decimal_sep, thousands_sep){ 
    var n = this,
    c = isNaN(decimals) ? 2 : Math.abs(decimals), 
    d = decimal_sep || '.', 
    
    t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    
    sign = (n < 0) ? '-' : '',
    
    i = parseInt(n = Math.abs(n).toFixed(c)) + '', 
    
    j = ((j = i.length) > 3) ? j % 3 : 0; 
    return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : ''); 
}

export default{
    methods: {
		formatAmount(amount){					
			//return amount != null ? Number(amount).format(2, ',', '.') : ""
			return amount != null ? amount.rnd(2).format(2, ',', '.') : ""
		},
		formatCurrency(amount){				
			return amount != null ? this.formatAmount(amount) + " €" : ""
		},
		formatPercent(amount){				
			return amount != null ? this.formatAmount(amount) + " %" : ""
		},
		formatDatetime(date, bMostrarHora){
			return (date ? format(date, "DD/MM/YYYY" + (bMostrarHora ? " HH:mm" : "")) : "")
		},
		formatTime(date){
			return (date ? format(date, "HH:mm") : "")
		},
		clone(o){
			return JSON.parse(JSON.stringify(o))
		},
		sortByKey(a,b,key){
			if(a[key] < b[key]) return -1
			if(a[key] > b[key]) return 1
			return 0
		}
	}
}