<template>
	<div class="calculadora-aplazame">
		<div
			data-aplazame-widget-instalments=""
			data-country="ES"
			:data-amount="amount"
			:data-view="type"
			data-type="select"
			data-option-primary-color="#000000"
			data-option-btn-bg-color="#000000"
			data-option-align="left"
			data-option-legal-advice="true"
			data-option-border-product="false"
			data-option-layout="horizontal"
			data-option-max-amount-desired="false"
			data-option-title-smart="false"
			data-option-branding="false"
			data-option-default-instalments="23"
			data-option-custom-styles="true"
		></div>
	</div>
</template>

<script>
export default {
	props: ['modo', 'importe', 'public-key'],
	data() {
		return {}
	},
	computed: {
		amount(){
			return this.importe*100
		},
		type(){
			switch(this.modo){
				case "carrito":	
					return 'cart'
				case "producto":	
				default:
					return 'product'
			}
		}
	},
	mounted(){
		setTimeout(() => {
			this.loadScript()
		}, 1)
	},
	methods: {
		loadScript(){
			let script = document.createElement('script')
			script.type = 'text/javascript'
			script.async = true
			script.defer = true			
			script.dataset.aplazame = this.publicKey
			script.dataset.sandbox = true
			script.src = 'https://cdn.aplazame.com/aplazame.js'
			document.head.appendChild(script)
		}
	}
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.calculadora-aplazame{
	.aplazame-widget-instalments{
		min-width: 300px;
		padding-left: 16px;
		padding-right: 32px;
		color: #000;
		border-radius: 8px;
		border: 1px solid #ddd;
		white-space: nowrap;
		margin-bottom: 12px;
		background-color: #fff;
		font-size: 15px;
		font-weight: 400;
		height: 45px;
		line-height: 45px;
		text-align: center;
		vertical-align: middle;
		z-index: 1;
		position:relative;
	}

	.aplazame-widget-instalments select {
		font-size: 13px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}

	 .aplazame-widget-from {
		text-transform: capitalize;
		display: inline-block;
	}

	 .aplazame-widget-amount {
		font-weight: 500;
	}

	.aplazame-widget-per-month {
		vertical-align: baseline;
	}

	.aplazame-widget-instalments-wrapper {
		font-weight: 400;
	}

	.aplazame-widget-instalments:before {
		background-image: url("data:image/svg+xml,<svg width='12' height='24' xmlns='http://www.w3.org/2000/svg'><g fill='%23000000' fill-rule='evenodd'><path d='M6 1.984L.973 8 0 7.161 6 0l6 7.161-.973.839zM6 22.016L11.027 16l.973.839L6 24l-6-7.161L.973 16z'/></g></svg>") !important;
	}

	.aplazame-widget-instalments:before {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 1.984L.973 8 0 7.161 6 0l6 7.161-.973.839zm0 20.032L11.027 16l.973.839L6 24l-6-7.161L.973 16z' fill='%230432ff' fill-rule='evenodd'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: calc(100% - 14px) 50%;
		background-size: 9px;
	}
	.aplazame-widget-instalments:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		border-radius: 2px;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		transition: -webkit-transform .2s ease;
		transition: transform .2s ease;
		transition: transform .2s ease,-webkit-transform .2s ease;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-font-smoothing: subpixel-antialiased;
		transition: all .2s,-webkit-transform .2s;
		transition: all .2s,transform .2s;
		transition: all .2s,transform .2s,-webkit-transform .2s;
	}

	sub, em{
		font-style: normal;
	}
}
</style>