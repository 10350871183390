<template>
	<div class="form-sorteo-wrapper">
		<div v-if="control.definicion">
			<div v-if="plazo_inscripcion == 'ABIERTO'">
				<transition-group name="fade">
					<div v-if="!control.bsent" key="form">
						<el-form ref="formSorteo" :model="data" :rules="validation_rules" :show-message="true" @submit.native.prevent :validate-on-rule-change="false">
							<template v-for="campo in aux.esquema">
								<el-form-item :key="campo.fieldname" :prop="campo.fieldname" :label="campo.type != 'boolean' ? campo.label : ''">
									<component class="fw" :is="campo.ui.component" v-model="data[campo.fieldname]" v-bind="campo.ui.attrs">
										<div v-if="campo.ui.slot" v-html="campo.ui.slot"></div>
										<template v-if="campo.ui.component == 'el-select'">
											<component :is="'el-option'" v-for="opt in campo.ui.attrs.options" :key="opt.value" :value="opt.value" :label="opt.label[codidioma]"/>
										</template>
									</component>
								</el-form-item>
							</template>
									
							<div class="actions" style="margin: 20px 0;text-align:center">
								<pln-button type="primary" size="large" @click="save" style="width:100%;max-width:500px;magin:auto"><icon icon="paper-plane" variant="regular"/> {{$msg('acciones.1')}}</pln-button>
							</div>

							<div class="rgpd" v-html="control.definicion.rgpd[codidioma]"/>
						</el-form>
					</div>
					
					<div v-if="control.bsent" class="confirmation" key="confirmacion">
						<icon icon="check-circle" variant="solid"/>
						<div class="confirmation-title" v-html="$msg('confirmacion.1', data)"></div>
						<div class="confirmation-message" v-html="$msg('confirmacion.2', data)"></div>
					</div>
				</transition-group>
			</div>
			<div class="inscripcion-cerrada" v-else v-html="mensajes_estado[plazo_inscripcion]"/>			
		</div>
	</div>
</template>

<script>
import PlnButton from '@/components/PlnButton'
import Icon from '@/components/Icon'
import { validateSpanishId } from 'spain-id'
import {isAfter as datefns_isAfter, parseISO as datefns_parseISO} from 'date-fns'
import i18n from '@/mixins/i18n'

export default {
	components: {PlnButton, Icon},
	props: ['id', 'url-api', 'msg-inscripcion-cerrada', 'msg-inscripcion-pendiente'],
	mixins: [i18n],
	data() {
		//this.loadi18nMessages('component', 'formulario_sorteo')
		this.$api.init(this.urlApi)

		return {
			data: {},
			aux: {
				esquema: []
			},
			control: {
				definicion: null,
				bsent: false
			},
			dict: {
				es: {
					"formulario": {
						"nombre": "Nombre",
						"apellidos": "Apellidos",
						"email": "Correo electrónico",
						"condiciones": "Confirmo haber leído y aceptar <a href='{url_condiciones}' target='_blank'>los términos y condiciones así como la política de privacidad de esta promoción</a>.",
						"newsletter": "Deseo participar en esta promoción y recibir el email de confirmación, así como futuras promociones, información sobre concursos y eventos exclusivos de INTECAT."
					},
					"acciones":{
						"1": "Enviar y participar"
					},
					"confirmacion": {
						"1": "¡Muchas gracias por participar, {nombre}!",
						"2": "Para confirmar tu participación al sorteo es necesario que valides tu dirección de correo electrónico ({email}). Por favor, comprueba tu bandeja de correo no deseado si no encuentras el mensaje.<br><br>¡Te deseamos mucha suerte!"
					},
					"validacion": {
						"1": "Campo obligatorio",
						"2": "La dirección de correo no tiene un formato válido",
						"3": "El documento de identidad no es válido",
						"4": "Ya existe una participación en el sorteo con este correo",
						"5": "La fecha no tiene un formato válido (DD/MM/YYYY)",
						"6": "La fecha introducida no es válida"
					},
					"estados": {
						"pendiente": "El plazo de participación en este sorteo todavía no está abierto.",
						"cerrado": "El plazo de participación en este sorteo está cerrado."
					}
				},
				ca: {
					"formulario": {
						"nombre": "Nom",
						"apellidos": "Cognoms",
						"email": "Correu electrònic",
						"condiciones": "Confirmo haver llegit i acceptar <a href='{url_condiciones}' target='_blank'>els termes i condicions així com la política de privacitat d'aquesta promoció</a>.",
						"newsletter": "Desitjo participar en aquesta promoció i rebre l'email de confirmació, així com futures promocions, informació sobre concursos i esdeveniments exclusius d'INTECAT."
					},
					"acciones":{
						"1": "Enviar i participar"
					},
					"confirmacion": {
						"1": "Moltes gràcies per participar, {nombre}!",
						"2": "Per a confirmar la teva partipació al sorteig és necessari que validis la teva adreça de correu electrònic ({email}). Si us plau, comprova la teva bústia de correu no desitjat si no trobes el missatge.<br><br>Et desitgem molta sort!"
					},
					"validacion": {
						"1": "Camp obligatori",
						"2": "L'adreça de correu no té un format vàlid",
						"3": "El document d'identitat no és vàlid",
						"4": "Ja existeix una participació al sorteig amb aquest correu",
						"5": "La data no té un format vàlid (DD/MM/YYYY)",
						"6": "La data introduïda no és vàlida"
					},
					"estados": {
						"pendiente": "El termini de participació en aquest sorteig encara no està obert.",
						"cerrado": "El termini de participació en aquest sorteig està tancat."
					}
				}
			}
		}
	},
	mounted(){
		this.init()
	},
	computed: {
		dthasta(){
			return this.control.definicion.dtsorteo || this.control.definicion.dthasta || null
		},
		dtdesde(){
			return this.control.definicion.dtdesde
		},
		plazo_inscripcion(){
			let fecha_actual = new Date()

			let babierto = this.dtdesde ? datefns_isAfter(fecha_actual, datefns_parseISO(this.dtdesde)) : true
			let bcerrado = this.dthasta ? datefns_isAfter(fecha_actual, datefns_parseISO(this.dthasta)) : false

			if (bcerrado) return "CERRADO"
			return babierto ? "ABIERTO" : "PDT_INICIO"
		},
		mensajes_estado(){
			return {
				"PDT_INICIO": this.msgInscripcionPendiente || this.$msg("estados.pendiente"),
				"CERRADO": this.msgInscripcionCerrada || this.$msg("estados.cerrado")
			}
		},
		validation_rules(){
			let rules = {}

			this.aux.esquema.forEach(campo => {
				let fieldRules = []
				if(campo.required){
					if(campo.type == 'boolean'){
						fieldRules.push({type: 'boolean', required: true, validator: this.validate_boolean, message: this.$msg('validacion.1'), trigger: 'blur'})
					}else{
						fieldRules.push({required: true, message: this.$msg('validacion.1'), trigger: 'blur'})
					}
				}
				
				if(campo.customValidation){
					campo.customValidation.forEach(cv => {
						fieldRules.push({validator: this['validate_' + cv], trigger: 'blur'})
					})
				}

				switch(campo.type){
					case "string":
						switch(campo.format){
							case "email":
								fieldRules.push({type: "email", message: this.$msg('validacion.2'), trigger: "blur"})
								break
						}
						break
					case "date":
						fieldRules.push({validator: this.validate_date, trigger: "blur"})
						break
					default:break
				}

				if(fieldRules.length > 0){
					rules[campo.fieldname] = fieldRules
				}
			})

			return rules
		}
	},
	methods: {			
		async init(){
			this.control.definicion = await this.$api.sorteos.get(this.id).getData()

			this.initEsquema()
			this.initValues()
		},
		initEsquema(){
			let esquema = []

			esquema.push({fieldname: 'nombre', type: 'string', maxlength: 100, label: this.$msg('formulario.nombre'), required: true, main: true})
			esquema.push({fieldname: 'apellidos', type: 'string', maxlength: 100, label: this.$msg('formulario.apellidos'), required: true, main: true})
			esquema.push({fieldname: 'email', type: 'string', format: 'email', customValidation: ['emailDisponible'], maxlength: 100, label: this.$msg('formulario.email'), required: true, main: true})

			this.control.definicion.esquema_formulario.forEach(campo => {
				campo.main = false
				campo.label = campo.label[this.codidioma]
				esquema.push(campo)
			})

			esquema.push({fieldname: 'bcondiciones', type: 'boolean', maxlength: 100, label: this.$msg('formulario.condiciones', {url_condiciones: this.control.definicion.url_condiciones}), required: true, main: true})
			esquema.push({fieldname: 'bnewsletter', type: 'boolean', maxlength: 100, label: this.$msg('formulario.newsletter'), required: true, main: true})

			esquema.forEach(campo => {
				let component
				let attrs = {}
				let slot
				let validation_rules = []

				if(campo.placeholder){
					attrs.placeholder = campo.placeholder[this.codidioma]
				}
				// Componente a renderizar
				switch(campo.type){
					case "date":
						/*component = 'el-date-picker'
						attrs.type = 'date'
						attrs.format = "dd/MM/yyyy"
						attrs["picker-options"] = {firstDayOfWeek: 1}
						attrs["v-on:blur"] = () => {
							console.log("blur")
						}*/
						component = "el-input"
						attrs.type = 'text'

						break
					case "string":			
						component = 'el-input'

						if (campo.maxlength) attrs.maxlength = campo.maxlength

						switch(campo.format){
							case "email":
								attrs.type = 'email'
								break
							case "phone":
								attrs.type = 'tel'
								break
							default:
								attrs.type = 'text'
								break
						}
						
						break
					case "boolean":
						component = 'el-checkbox'
						slot = campo.label
						break
					case "select":						
						component = 'el-select'
						attrs.options = campo.options
						attrs.multiple = campo.multiple
						break
					default:
						component = 'el-input'
						break
				}

				campo.ui = {component, attrs, validation_rules, slot}
			})

			this.aux.esquema = esquema
		},
		initValues(){
			let data = {}

			this.aux.esquema.forEach(campo => {
				data[campo.fieldname] = null
			})

			this.data = data
		},
		async validate(){
			let form = this.$refs['formSorteo']
			
			return new Promise((resolve, reject) =>{
				this.$nextTick(() => {
					form.validate((valid, obj)=> {
						return resolve(valid)
					})
				})
			})
		},
		validate_boolean(rule, value, callback){
			if (value) callback()
			else callback(new Error())
		},
		validate_docident(rule, value, callback){
			let bvalid = validateSpanishId(value)

			if(bvalid) callback()
			else callback(new Error(this.$msg('validacion.3')))
		},
		validate_date(rule, value, callback){
			if (!value) return callback()
			
			let formatValid = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/.test(value)			
			if (!formatValid) return callback(new Error(this.$msg('validacion.5')))
			
			let [day, month, year] = value.split("/")
			month = month-1

			let date = new Date(year, month, day)
			let dateValid = date.getDate() == day && date.getMonth() == month && date.getFullYear() == year

			if (!dateValid) return callback(new Error(this.$msg('validacion.6')))
			callback()			
		},
		async validate_emailDisponible(rule, value, callback){
			let bvalid = await this.$api.sorteos.get(this.id).checkEmailDisponible(value)

			if(bvalid) callback()
			else callback(new Error(this.$msg('validacion.4')))
		},
		async save(){
			let bValid = await this.validate()

			if(bValid){
				let params = {
					codidioma: this.codidioma,
					extra: {}
				}

				this.aux.esquema.forEach(campo => {
					let val = this.data[campo.fieldname]

					if(campo.type == 'date'){
						let [day, month, year] = val.split("/")
						val = year + "-" + month + "-" + day
					}

					if(campo.type == 'select'){
						let opt = campo.options.find(opt => opt.value == val)	
						params[campo.fieldname] = {value: val, label: opt.label}
					}

					if(campo.main){
						params[campo.fieldname] = val
					}else{
						params.extra[campo.fieldname] = val
					}
				})
				
				try{
					await this.$api.sorteos.get(this.id).nuevoParticipante(params)
					this.control.bsent = true					
				}catch(e){
					console.log(e)
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.fade-enter-active {
	transition: all 0.5s ease;
}
.fade-leave-active {
	position: absolute;
	transition: all 0.5s ease;
}
.fade-enter, .fade-leave-to{
	opacity: 0;
}

.confirmation{
	background-color: #f0f9eb;
    color: #67c23a;
	padding:20px;
	border-radius:10px;
	text-align:center;

	.icon{
		display:block;
		font-size:28px;
		margin-bottom:20px;
	}

	.confirmation-title{
		font-size:18px;
		font-weight:700;
		margin-bottom:20px;
	}

	.confirmation-message{
		max-width: 800px;
		margin:auto;
	}
}

.inscripcion-cerrada{
	padding:10px;
	border-radius:6px;
	background-color: #fdf6ec;
    color: #e6a23c;
	text-align:center;
}
</style>

<style lang="scss">
.form-sorteo-wrapper{
	.el-form{
		.el-form-item{
			margin-bottom:10px;

			&.is-required{
				.el-form-item__label, .el-checkbox__label{
					&:after{
						content: '*';
						margin-left:3px;
						color:red;
					}
				}
			}
		}

		.el-form-item__error{
			position:relative;
			top:0;
		}

		.el-checkbox{
			margin-bottom:0;

			.el-checkbox__label > div{
				display:inline;
			}
		}
	}
}
</style>