<template>
	<div ref="drawer" class="i-drawer-wrapper" :class="{'visible': visible}">
		<div ref="drawer-content" class="i-drawer-content" :style="{width: this.size}">
			<div class="i-drawer-header" v-if="withHeader">
				<div class="i-drawer-title">{{title}}</div>
				<button class="i-drawer-close" type="button" aria-label="Cerrar" @click="close">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" style="width:16px" fill="none" viewBox="0 0 18 17">
						<path d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z" fill="currentColor"></path>
					</svg>
				</button>
			</div>
			<div class="i-drawer-body" :style="bodyStyles" :key="times_visible">
				<slot/>
			</div>
			<div class="i-drawer-footer">
			</div>
		</div>
		<div class="i-drawer-overlay" @click="close"/>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: ""
		},
		size: {
			type: String,
			default: "500px"
		},
		withHeader: {
			type: Boolean,
			default: true
		},
		paddingInBody: {
			type: Boolean,
			default: true
		}
	},
	data(){
		return {
			visible: false,
			times_visible: 0
		}
	},
	computed: {
		bodyStyles(){
			let result = {}
			if(!this.paddingInBody) result.padding = "0"
			return result
		}
	},
	mounted(){
		this.init()
	},
	methods: {
		init(){
			let drawer = this.$refs.drawer
			let parent = drawer.parentNode

			parent.removeChild(drawer)
			document.body.appendChild(drawer)
		},
		open(){
			this.$refs["drawer-content"].scrollTop = 0
			this.visible = true	
			this.times_visible++		
			document.addEventListener('keydown', this.handleEscape.bind(this))
			document.documentElement.classList.add("non-scrollable")
		},
		close(){
			this.visible = false
			document.removeEventListener('keydown', this.handleEscape.bind(this))
			document.documentElement.classList.remove("non-scrollable")
		},
		handleEscape(e){
			if (e.key === 'Escape') this.close()
		}
	}
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.i-drawer-wrapper{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	pointer-events:none;
	opacity:0;
	transition:0.3s all ease;
	z-index:1000;
	color:#121212;

	.i-drawer-content{
		position:absolute;
		top:0;
		right:0;
		background: #fff;
		height:100%;
		z-index:1001;
		max-width:100%;

		//border-left:1px solid rgba(18,18,18,0.1);
		transition:0.3s all ease;
		transform: translate3d(100%,0,0);
		opacity:0;
		overflow:auto;
		display:flex;
		flex-direction:column;
	}

	&.visible{
		opacity:1;
		pointer-events:initial;

		.i-drawer-content{
			transition:0.3s all ease;
			transform: translate3d(0,0,0);
			opacity:1;
		}
	}

	.i-drawer-overlay{
		background:rgba(0,0,0,0.2);
		z-index:1000;
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:block;
	}

	.i-drawer-header{
		padding: 24px;
		
		display:flex;
		gap:20px;

		.i-drawer-title{
			font-family: SF Pro Text, Helvetica, Arial, sans-serif;
			font-size:16px;
			flex:1;
		}

		border-bottom:1px solid #eee;
	}

	.i-drawer-close{
		/*position:absolute;
		top:24px;
		right:24px;*/
		outline:none;
		background:none;
		border:none;

		&:hover{
			opacity:0.8;
			cursor:pointer;
		}
	}

	.i-drawer-body{
		padding: 24px;
		flex:1;
	}

	.i-drawer-header+.i-modal-body{
		padding-top:0;
	}
}

/*@media screen and (min-width: 750px){
	.i-drawer .i-drawer-content{
		//transform: translate(100%);
		width: 375px;
	}	
}*/

@media screen and (max-width: 750px){
	.i-drawer-wrapper {
		.i-drawer-content{
			width:100%;
			height: calc(100% - 50px);
			transform: translate3d(0,100%,0);
			bottom:0;
			top:auto;
			right:auto;
		}

		&.visible{
			.i-drawer-content{
				transform: translate3d(0,0,0);
			}
		}
	}
}

html.non-scrollable{
	overflow:hidden !important;
}
</style>