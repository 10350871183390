//CCD: Campaña Captación Datos
import CCD from "./ccd"

export default class CCDs {
	constructor(deps){
		this.deps = deps
		this.axios = this.deps.axios
		this.api = this.deps.api
	}

	get(id){
		return new CCD(id, this.deps)
	}

	async validarEmail(codigo){
		return await this.api.post('ccds/validarEmail', {codigo})
	}
}