<template>
	<div class="i-share-options">
		<template v-for="option in available_options">
			<el-tooltip :key="option.mode" effect="dark" :content="option.label" :placement="tooltipPlacement" :disabled="screenWidth < 800">
				<button @click="share(option.mode)"><icon :icon="option.icon" :variant="option.iconVariant"/></button>
			</el-tooltip>
		</template>
	</div>
</template>

<script>
import Icon from '@/components/Icon'
import i18n from '@/mixins/i18n'

export default {
	props: {
		options: {
			type: String,
			default: ""
		},
		tooltipPlacement: {
			type: String,
			default: "bottom"
		},
		direction: {
			type: String,
			default: 'row'
		}
	},
	components: {Icon},
	mixins: [i18n],
	data(){
		return {
			dict: {
				es: {
					"1": "Enlace copiado",
					"share": {
						"facebook": "Compartir en Facebook",
						"twitter": "Compartir en Twitter",
						"linkedin": "Compartir en LinkedIn",
						"whatsapp": "Compartir en Whatsapp",
						"email": "Compartir por correo",
						"clipboard": "Copiar enlace"
					}
				},
				ca: {
					"1": "Enllaç copiat",
					"share": {
						"facebook": "Compartir a Facebook",
						"twitter": "Compartir a Twitter",
						"linkedin": "Compartir a LinkedIn",
						"whatsapp": "Compartir a WhatsApp",
						"email": "Compartir per correu",
						"clipboard": "Copiar enllaç"
					}
				}
			}
		}
	},
	computed: {
		screenWidth(){
			return window.innerWidth			
		},
		available_options(){
			let options = [
				{mode: 'facebook', icon: 'facebook-f', iconVariant: 'brand', label: this.$msg('share.facebook')},
				{mode: 'twitter', icon: 'twitter', iconVariant: 'brand', label: this.$msg('share.twitter')},
				{mode: 'linkedin', icon: 'linkedin-in', iconVariant: 'brand', label: this.$msg('share.linkedin')},
				{mode: 'whatsapp', icon: 'whatsapp', iconVariant: 'brand', label: this.$msg('share.whatsapp')},
				{mode: 'email', icon: 'envelope', iconVariant: 'regular', label: this.$msg('share.email')},
				{mode: 'clipboard', icon: 'link', iconVariant: 'regular', label: this.$msg('share.clipboard')}
			]

			let tmp = this.options.split(",")
			let result = []

			tmp.forEach(t => {
				let opt = options.find(o => o.mode == t.trim().toLowerCase())
				if (opt) result.push(opt)
			})
			
			return result
		}
	},
	methods: {
		share(mode){
			let pageUrl = location.href
			let pageTitle = document.title
			let url = null

			switch(mode){
				case "facebook":
					url = "http://m.facebook.com/sharer.php?u=" + encodeURIComponent(pageUrl)
					break
				case "twitter":
					url = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(pageTitle) + "&url=" + encodeURIComponent(pageUrl)
					break
				case "linkedin":
					url = "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(pageUrl)
					//url = "https://www.linkedin.com/sharing/share-offsite/?url=" + pageUrl
					break
				case "whatsapp":
					url = "https://wa.me/?text=" + encodeURIComponent(pageUrl)
					break
				case "email":
					location.href = "mailto:?subject=" + encodeURIComponent(pageTitle) + "&body=" + encodeURIComponent(pageUrl)
					return
					break
				case "clipboard":
					navigator.clipboard.writeText(pageUrl)
					this.$message.success(this.$msg('1'))
					return
					break
			}

			window.open(url, 'share-popup', 'left=20,top=20,width=500,height=500,toolbar=1,resizable=0')
		}
	}
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.i-share-options{
	display:flex;
		
	button{
		color:#2a2a2a;
		outline:none;
		border:none;
		background:none;
		padding:5px;
		width:40px;
		height:40px;
		text-align:center;
		font-size:18px;
		transition:0.3s all ease;
		border-radius:6px;
		opacity:1;

		&:hover{
			cursor:pointer;
			//color:#4949bc;
			background:#f1f2f3;
			opacity:1;
		}
	}
}
</style>