<template>
	<div>
		<div class="i-newsletter">
			<div class="i-newsletter-title" v-if="titulo">{{titulo}}</div>

			<div v-if="!control.bsent">
				<el-form ref="form" :model="data" :rules="validation_rules" label-width="120px" label-position="top" @submit.native.prevent>
					<!--icon icon="envelope-open-text" variant="light" class="newsletter-icon"/-->
					
					<!--p>Suscríbete a nuestro boletín y no te pierdas ni un detalle sobre los productos más novedosos, fantásticas ofertas personalizadas y nuestros eventos exclusivos.</p-->

					<el-form-item prop="email">
						<el-input v-model="data.email" type="text" class="fw" placeholder="Tu dirección de correo electrónico..." maxlength="150">
							<button type="submit" slot="append" @click="signUp">
								<icon icon="arrow-right" variant="light"/>
							</button>
						</el-input>
					</el-form-item>
					<!--el-row :gutter="20">
						<el-col :xs="24" :sm="16">
							<el-form-item prop="email">
								<el-input v-model="data.email" type="text" class="fw" placeholder="Tu dirección de correo electrónico..." maxlength="150"/>							
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="8">
							<el-button type="primary" class="fw" @click="signUp"><icon icon="paper-plane"/> Suscribirme</el-button>					
						</el-col>
					</el-row-->

					<div class="politica-privacidad">
						<el-form-item prop="bpolitica_privacidad">
							<el-checkbox v-model="data.bpolitica_privacidad">
								<span v-html="$msg('2', {url_privacidad: urlPrivacidad, url_condiciones: urlCondiciones})"></span>
								<!--He leído y acepto la <a :href="urlPrivacidad" target='_blank'>política de privacidad</a> y los <a :href="urlCondiciones" target='_blank'>términos y condiciones</a>-->
							</el-checkbox>
						</el-form-item>
					</div>

					<!--div class="rgpd">
						<p class="text-center" style="margin-bottom:5px;"><b>{{$msg('rgpd.1')}}</b></p>
						<p><b>{{$msg('rgpd.2')}}:</b> {{$msg('rgpd.3')}}</p>
						<p><b>{{$msg('rgpd.4')}}:</b> {{$msg('rgpd.5')}}</p>
						<p><b>{{$msg('rgpd.6')}}:</b> {{$msg('rgpd.7')}}</p>
						<p><b>{{$msg('rgpd.8')}}: </b> {{$msg('rgpd.9')}}</p>
						<p><b>{{$msg('rgpd.10')}}:</b> <span v-html="$msg('rgpd.11')"></span></p>
						<p><b>{{$msg('rgpd.12')}}:</b> <span v-html="$msg('rgpd.13', {url_privacidad: $i18n.path('politica-privacidad')})"></span></p>
					</div-->
				</el-form>
			</div>
			<div v-else>
				<!--icon icon="check-circle" variant="light" class="newsletter-icon"/-->
				<p><icon icon="check"/> {{$msg('3')}}</p>
				<p v-html="$msg('4')"></p>
			</div>
		</div>


		<!--div class="footer-block__newsletter">
			<h2 class="footer-block__heading2">Subscribe to our emails</h2>
				<form method="post" action="/contact#ContactFooter" id="ContactFooter" accept-charset="UTF-8" class="footer__newsletter newsletter-form" onsubmit="window.Shopify.recaptchaV3.addToken(this, &quot;customer&quot;); return false;">
				<input type="hidden" name="form_type" value="customer">
				<input type="hidden" name="utf8" value="✓">
				<input type="hidden" name="contact[tags]" value="newsletter">
              	<div class="emailTextBoxFooter1 newsletter-form__field-wrapper">
                	<div class="  field">
                  		<input id=" NewsletterForm--footer" type="email" name="contact[email]" class="emailTextBoxFooter1 field__input" value="" aria-required="true" autocorrect="off" autocapitalize="off" autocomplete="email" placeholder="Email" required="">
                  		<label class="email_label_css field__label" for="NewsletterForm--footer">Email</label>
                  		<button type="submit" class="newsletter-form__button field__button" name="commit" id="Subscribe" aria-label="Subscribe">
                    		<svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" class="footer_mail_arrow icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
  								<path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor"></path>
							</svg> 
                  		</button>
                	</div>
				</div>
			</form>
		</div-->
	</div>
</template>


<script>
import Icon from '@/components/Icon'
import i18n from '@/mixins/i18n'

export default {
	props: {
		"origen": String,
		"idtipocliente": Number,
		"url-api": String,
		"url-privacidad": String,
		"url-condiciones": String,
		"titulo": String
	},
	components: {Icon},
	mixins: [i18n],
	data(){
		this.$api.init(this.urlApi)

		return {
			data: {
				email: null,
				bpolitica_privacidad: false
			},
			control: {
				bsent: false
			},
			validation_rules: {
				email: [
					{required: true, message: 'Campo obligatorio', trigger: "submit"},
					{message: "Dirección de correo inválida", type:"email", trigger: "submit"},
					{validator: this.checkEmailAddress, trigger: "submit"}
				],
				bpolitica_privacidad: {validator: this.checkPrivacidad, trigger: "submit"}
			},
			dict: {
				es: {
					"1": "Suscríbete a nuestro boletín.",
					"2": "He leído y acepto la <a href='{url_privacidad}' target='_blank'>política de privacidad</a> y los <a href='{url_condiciones}' target='_blank'>términos y condiciones</a>",
					"3": "¡Muchas gracias por suscribirte!",
					"4": "En breve recibirás un correo electrónico para que puedas confirmar tu suscripción.<br>Si no encuentras el mensaje, revisa la carpeta de spam de tu correo.",
					"validaciones": {
						"1": "Es necesario aceptar la política de privacidad",
						"2": "Este correo electrónico ya forma parte de nuestro boletín"
					}
				},
				ca: {
					"1": "Subscriu-te al nostre butlletí.",
					"2": "He llegit i accepto la <a href='{url_privacidad}' target='_blank'>política de privacitat</a> i els <a href='{url_condiciones}' target='_blank'>termes i condicions</a>",
					"3": "Moltes gràcies per subscriure't!",
					"4": "En breu rebràs un correu electrònic perquè puguis confirmar la teva subscripció.<br>Si no trobes el missatge, revisa la carpeta de spam del teu correu.",
					"validaciones": {
						"1": "És necessari acceptar la política de privacitat",
						"2": "Aquest correu electrònic ja forma part del nostre butlletí"
					}
				}
			}
		}
	},
	methods: {
		async signUp(){
			let valid = await this.validate()
			if(valid){
				try{
					let idtipocliente = null
					if(this.idtipocliente){
						// Solo permitimos los valores 1 (Particular), 2 (Autónomo) o 3 (Empresa)
						if ([1,2,3].includes(this.idtipocliente)) idtipocliente = this.idtipocliente
					}

					let input = {
						email: this.data.email, 
						origen: this.origen,
						codidioma: this.codidioma, 
						datos: {
							codidioma: this.codidioma,
							idtipocliente: idtipocliente
						}
					}

					await this.$api.suscripciones.invitar(input)
					
					this.control.bsent = true
				}catch(e){
					console.log(e)
				}
			}
		},
		async validate(){
			let form = this.$refs['form']
			
			return new Promise((resolve, reject) =>{
				this.$nextTick(() => {
					form.validate((valid, obj)=> {
						return resolve(valid)
					})
				})
			})
		},
		checkPrivacidad(rule, value, callback){
			if(value) callback()
			else callback(new Error(this.$msg('validaciones.1')))
		},
		async checkEmailAddress(rule,value,callback) {
			let data = await this.$api.suscripciones.emailCheck(this.data.email)
			if (data) callback()
			else callback(new Error(this.$msg('validaciones.2')))
		}
	}
}
</script>

<style lang="scss">
.i-newsletter{
	/*background:#d7f3fa;*/
	font-family: SF Pro Text;
	font-size:14px;

	.flex{
		display:flex;
		justify-content: center;
		align-items: center;
	}

	.rgpd{
		/*background: rgba(255,255,255,0.3);*/
		background: #fafafa;
		border:none;
		p{
			font-size:10.5px !important;
			margin-bottom:2px !important;
			line-height:10px;
			opacity:0.7;
		}
	}

	.el-form-item{
		margin-bottom:0;

		.el-form-item__content{
			line-height:auto;
		}

		.el-form-item__error{
			position:relative;
		}
	}

	.el-input{
		position:relative;
		/*border:1px solid red;
		border-radius:4px;*/

		.el-input__inner{
			//border:none;
			border-radius:6px;
			padding-right:40px;
		}

		&:focus{
			border-color:red;
		}
	}

	.el-input-group__append, .el-input-group__prepend{
		background:none;
		border:none;
		position:absolute;
		top:0;
		right:0;
		width:auto;
		padding:0;

		.el-button{
			border:none;
			margin:0 !important;
			
			height:40px;
		}
	}

	button{
		width:40px;
		height:40px;
		background:none;
		border:none;
		outline:none;

		opacity:0.5;
		&:hover{
			opacity:1;
			cursor:pointer;
		}
	}
	

	.i-newsletter-title{
		margin-bottom:8px;
		font-size: 18px;
		line-height: 21px;
		text-align: left;
		vertical-align: top;
		font-weight: 400;
		font-family: SF Pro Text;
		margin: 0;
		padding-bottom: 16px;
	}

	a{
		color: var(--color-link-dynamic-label) !important;
	}
}

@media (max-width: 767px){
	.politica-privacidad{
		margin-top:20px;
	}
}
</style>
